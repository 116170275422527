import { useEffect, useState } from 'react'
import { AdasBox, AdasContainer, AdasPaper, AdasButton, AdasDrawer, AdasTypography } from '@components/wrapper-components'
import CloseIcon from '@mui/icons-material/Close'
import { ScheduleCalendar } from '@components/scheduler'
import { useHistory } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import { Logger } from '../../logger'
import { getOrganisationByReferenceId, setupOrganisationByCCId } from '../../api/api'
import { useCenterStore, useOrganisationStore, useLoadingStore, useMessageStore } from '@caradasstore'
import { CreateAppointmentForm } from '@components/scheduler/schedule-calendar/CreateAppointmentForm'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { useUserStore } from '@caradasstore/UserStore'
import { usePolling } from 'utils/usePolling'
import { SCHEDULER_AUTO_REFRESH_MINUTES } from 'constants'
import { useDeviceQueries } from '@hooks/useDeviceQueries'
export const SchedulerPage = () => {
  const history = useHistory()
  const ccId = useCenterStore((store) => store.ccId)
  const setOrganisationDetail = useOrganisationStore((store) => store.setOrganisationDetail)
  const setLoading = useLoadingStore((store) => store.setLoading)
  const [showOrganisationSetup, setShowOrganisationSetup] = useState(false)
  const [showDefaultPage, setShowDefaultPage] = useState(true)
  const currentUser = useUserStore((store) => store.currentUser)
  const addMessage = useMessageStore((store) => store.addMessage)
  const { isMobile, isLandscape } = useDeviceQueries()

  const [newEvent, setNewEvent] = useState(false)
  const initialView = isMobile && !isLandscape ? 'timeGridDay' : 'timeGridWeek' // for mobile and portrait then show day grid

  const redirectToCalendar = () => {
    history.push('/calendar')
  }
  const [refresh, setRefresh] = useState(false)

  usePolling(setRefresh, refresh, SCHEDULER_AUTO_REFRESH_MINUTES)

  useEffect(() => {
    console.log('Refresh state changed:', refresh) // Debug log

    const fetchOrganisationDetailsByCcId = async () => {
      setLoading(true)
      const orgResponse = await getOrganisationByReferenceId({ ccId: ccId })
      setLoading(false)
      if (orgResponse?.status === 200) {
        if (orgResponse?.data.id) {
          setOrganisationDetail(orgResponse.data)
          setShowDefaultPage(false)
        } else if (orgResponse?.data?.id === null) {
          setShowOrganisationSetup(true)
        }
      } else {
        Logger.error({
          message: 'Failed to fetch organisation data',
          payload: { file: 'calendarpage', method: 'getOrganisationByReferenceId' }
        })
      }
    }
    if (ccId) {
      fetchOrganisationDetailsByCcId(ccId)
    }
  }, [ccId, setOrganisationDetail, setShowOrganisationSetup, setShowDefaultPage])

  const extractMessage = (text) => {
    if (typeof text !== 'string' || !text.trim()) {
      return null
    }

    const messageMatch = text.match(/"message":"(.*?)"/)

    if (messageMatch && messageMatch[1]) {
      return messageMatch[1].replace('Bad arguments sent: ', '')
    }

    return null
  }
  const handleCustomError = (error) => {
    let errorMessage = ''
    if (error?.response?.status === 400) {
      errorMessage = extractMessage(error.response?.data?.message || '')
    }
    addMessage({
      text: errorMessage || error.response?.data?.message
    })
  }

  const handleSetupOrganisationConfirm = async () => {
    try {
      setLoading(true)
      const res = await setupOrganisationByCCId(ccId)
      setLoading(false)
      if (res?.status === 200) {
        setOrganisationDetail(res.data)
        setShowDefaultPage(false)
      }
    } catch (error) {
      Logger.error({
        message: 'Failed to setup organisation',
        payload: { file: 'calendarpage', method: 'setupOrganisationByCCId' }
      })
      handleCustomError(error)
    } finally {
      setLoading(false)
    }
  }

  const canEditSettings = () => {
    return (currentUser && currentUser.is_owner) || currentUser.is_manager || currentUser.is_admin
  }

  const openNewEventSlider = () => {
    setNewEvent(true)
  }

  return (
    <AdasContainer maxWidth='lg'>
      <AdasPaper
        sx={{
          padding: '1.25rem 1.25rem',
          marginTop: '1.25rem',
          backgroundColor: 'white',
          marginBottom: '40px'
        }}
      >
        {!showDefaultPage && (
          <>
            <AdasBox
              sx={{
                display: 'flex',
                justifyContent: 'end',
                flexDirection: { xs: 'column', md: 'row' }
              }}
            >
              <AdasBox
                display='flex'
                gap={2}
                sx={{
                  justifyContent: isMobile || isLandscape ? 'space-between' : 'flex-end',
                  flexDirection: 'row',
                  marginBottom: '2rem'
                }}
              >
                {canEditSettings() && (
                  <AdasButton
                    startIcon={<SettingsOutlinedIcon />}
                    onClick={redirectToCalendar}
                    sx={{
                      marginRight: isMobile || isLandscape ? 'auto' : '0',
                      padding: { xs: '6px', sm: '6px 8px' }
                    }}
                  >
                    CALENDAR SETTINGS
                  </AdasButton>
                )}
                <AdasButton
                  variant='contained'
                  color='primary'
                  onClick={openNewEventSlider}
                  sx={{
                    marginLeft: isMobile ? 'auto' : '0'
                  }}
                >
                  NEW EVENT
                </AdasButton>
              </AdasBox>
            </AdasBox>
            <CssBaseline />
            <ScheduleCalendar
              initialView={initialView}
              refresh={refresh}
              setRefresh={setRefresh}
              openNewEventSlider={() => {
                openNewEventSlider()
              }}
            />
          </>
        )}

        {showDefaultPage && (
          <AdasBox
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            gap={2}
            sx={{ minHeight: '81vh', overflow: 'hidden' }}
          >
            {showOrganisationSetup && (
              <>
                <AdasBox>
                  <AdasTypography>Please setup the Scheduler for managing the appointments</AdasTypography>
                </AdasBox>
                <AdasButton variant='contained' onClick={handleSetupOrganisationConfirm}>
                  SETUP
                </AdasButton>
              </>
            )}
          </AdasBox>
        )}
      </AdasPaper>

      {newEvent && (
        <AdasPaper elevation={0} sx={{ padding: '1.25rem', marginTop: '1.25rem', marginBottom: '20px' }}>
          <AdasDrawer anchor={'right'} open={newEvent} onClose={() => setNewEvent(false)}>
            <AdasBox sx={{ width: { xs: '340px', sm: '420px' } }}>
              <AdasBox sx={{ margin: '25px 24px' }}>
                <span>Create New Appointment</span>
                <CloseIcon onClick={() => setNewEvent(false)} sx={{ float: 'right' }} fontSize='small'></CloseIcon>
              </AdasBox>
              <CreateAppointmentForm onClose={() => setNewEvent(false)} setRefresh={setRefresh} />
            </AdasBox>
          </AdasDrawer>
        </AdasPaper>
      )}
    </AdasContainer>
  )
}
