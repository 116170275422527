import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import { useCenterStore } from '@caradasstore/CenterStore'
import { AdasFormControl, AdasInputAdornment, AdasSearchMenu } from '@components/wrapper-components'
import { updateAssignee, updateTicketAssignee } from '../../api/api'
import { Logger } from '../../logger'
import './assignedUser.css'

export const AssignedUser = (props) => {
  const {
    initialAssignee,
    ticketNumber,
    workOrderId,
    closedStatus = false,
    type,
    isTicket = false,
    isAdminUsersOnly = false,
    onChange,
    updatePostTestDriveBy
  } = props

  const [assignee, setAssignee] = useState('')
  const userList = useCenterStore((store) => store.userList)
  const handleAssigneeChange = (e) => {
    if (updatePostTestDriveBy) {
      updatePostTestDriveBy(userList.find((user) => user.id === e.target.value))
      return
    }
    const updateFunc = isTicket ? updateTicketAssignee : updateAssignee
    const id = isTicket ? ticketNumber : workOrderId

    const updateAssigneeResponse = updateFunc({
      id,
      userId: e.target.value,
      type
    })

    updateAssigneeResponse
      .then((response) => {
        if (response.status === 200) {
          setAssignee(e.target.value)
          if (onChange) {
            const assignedUser = userList.find((user) => user.id === e.target.value)
            if (assignedUser) onChange(assignedUser)
          }
        }
      })
      .catch((error) => {
        Logger.error({ message: error, payload: { file: 'assignedUser', method: 'updateAssignee' } })
      })
  }

  const generateOptions = () => {
    const options = userList
      .filter((user) => (isAdminUsersOnly ? user.is_admin : !user.is_admin))
      .map((user) => ({
        value: user.id,
        name: user.name
      }))

    options.push({ value: false, name: 'Unassigned' })
    if (initialAssignee && assignee === initialAssignee.id && initialAssignee.is_admin) {
      const assignedUserExists = options.find((user) => user.value === initialAssignee.id)
      if (!assignedUserExists) {
        options.push({
          value: initialAssignee.id,
          name: initialAssignee.name
        })
      }
    }
    return options.filter(Boolean)
  }

  useEffect(() => {
    if (userList.length > 0) {
      const initialUserId =
        userList.find((user) => {
          return user?.id === initialAssignee?.id
        })?.id || false
      setAssignee(initialUserId)
    }
  }, [initialAssignee, userList])

  return (
    <AdasFormControl className='adas-form-control'>
      <AdasSearchMenu
        fullWidth
        id='assignee'
        name='assignee'
        options={generateOptions()}
        value={assignee}
        onChange={handleAssigneeChange}
        disabled={!!closedStatus}
        className='adas-search-menu'
        SelectProps={{
          IconComponent: () => null,
          className: 'adas-search-menu-select',
          disabled: !!closedStatus
        }}
        InputProps={{
          endAdornment: (
            <AdasInputAdornment position='end' className='adas-input-adornment'>
              <ModeEditOutlineOutlinedIcon className='edit-icon' />
            </AdasInputAdornment>
          )
        }}
      />
    </AdasFormControl>
  )
}

AssignedUser.propTypes = {
  initialAssignee: PropTypes.object,
  workOrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ticketNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  closedStatus: PropTypes.bool,
  isTicket: PropTypes.bool,
  isAdminUsersOnly: PropTypes.bool,
  onChange: PropTypes.func,
  updatePostTestDriveBy: PropTypes.func
}
