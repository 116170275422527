import {
  AdasBox,
  AdasContainer,
  AdsCustomModal,
  AdasPaper,
  AdasTabs,
  AdasTypography,
  AdasButton,
  AdasDrawer
} from '@components/wrapper-components'
import { useState, useEffect, useRef } from 'react'
import { useTheme } from '@mui/material'
import { useHistory } from 'react-router-dom'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import ScheduleAvailability from '@components/calendar-settings/schedule-availability/ScheduleAvailability'
import DateOverrides from '@components/calendar-settings/date-overrides/DateOverrides'
import CalendarPreferences from '@components/calendar-settings/calendar-preferences/CalendarPreferences'
import BookingLink from './BookingLink'
import CloseIcon from '@mui/icons-material/Close'
import { Logger } from '../../logger'
import { getOrganisationByReferenceId, setupOrganisationByCCId } from '../../api/api'
import { useCenterStore, useOrganisationStore, useLoadingStore, useMessageStore } from '@caradasstore'

export const CalendarPage = () => {
  const [calendarType, setCalendarType] = useState('schedule-availability')
  const [bookingLinkOpen, setBookingLinkOpen] = useState(false)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
  const [nextLocation, setNextLocation] = useState(null)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [showOrganisationSetup, setShowOrganisationSetup] = useState(false)
  const [showDefaultPage, setShowDefaultPage] = useState(true)
  const [pendingTabChange, setPendingTabChange] = useState(null)
  const addMessage = useMessageStore((store) => store.addMessage)

  const history = useHistory()
  const theme = useTheme()
  const unblockRef = useRef(null)

  const ccId = useCenterStore((store) => store.ccId)
  const setOrganisationDetail = useOrganisationStore((store) => store.setOrganisationDetail)
  const setLoading = useLoadingStore((store) => store.setLoading)

  useEffect(() => {
    if (ccId) fetchOrganisationDetailsByCcId()
  }, [ccId, setOrganisationDetail, setShowOrganisationSetup, setShowDefaultPage])

  const fetchOrganisationDetailsByCcId = async () => {
    setLoading(true)
    const orgResponse = await getOrganisationByReferenceId({ ccId })
    setLoading(false)
    if (orgResponse?.status === 200) {
      // Show Scheduler Details if Organisation Exists
      if (orgResponse?.data.id) {
        setOrganisationDetail(orgResponse.data)
        setShowDefaultPage(false)
      }
      // Show scheduler setup screen if organisation doesn't exist
      else if (orgResponse?.data?.id === null) {
        setShowOrganisationSetup(true)
      }
    } else {
      Logger.error({
        message: 'Failed to fetch organisation data',
        payload: { file: 'calendarpage', method: 'getOrganisationByReferenceId' }
      })
    }
  }
  const handleUnsavedChangesConfirm = ({ type }) => {
    if (type === 'confirm') {
      setHasUnsavedChanges(false)
      setOpenConfirmationDialog(false)
      setPendingTabChange(null)

      if (unblockRef.current) {
        unblockRef.current()
      }
      if (pendingTabChange) {
        setCalendarType(pendingTabChange)
      }
      if (nextLocation) {
        history.push(nextLocation.pathname)
        setNextLocation(null)
      }
    } else if (type === 'close') {
      setOpenConfirmationDialog(false)
    }
  }

  const extractMessage = (text) => {
    if (typeof text !== 'string' || !text.trim()) {
      return null
    }

    const messageMatch = text.match(/"message":"(.*?)"/)

    if (messageMatch && messageMatch[1]) {
      return messageMatch[1].replace('Bad arguments sent: ', '')
    }

    return null
  }
  const handleCustomError = (error) => {
    let errorMessage = ''
    if (error?.response?.status === 400) {
      errorMessage = extractMessage(error.response?.data?.message || '')
    }
    addMessage({
      text: errorMessage || error.response?.data?.message
    })
  }

  const handleSetupOrganisationConfirm = async () => {
    try {
      setLoading(true)
      const res = await setupOrganisationByCCId(ccId)
      if (res?.status === 200) {
        setOrganisationDetail(res.data)
        setShowDefaultPage(false)
      }
    } catch (error) {
      Logger.error({
        message: 'Failed to setup organisation',
        payload: { file: 'calendarpage', method: 'setupOrganisationByCCId' }
      })
      handleCustomError(error)
    } finally {
      setLoading(false)
    }
  }

  const onTabChange = (e, value) => {
    if (hasUnsavedChanges) {
      setPendingTabChange(value)
      setOpenConfirmationDialog(true)
    } else {
      setCalendarType(value)
    }
  }

  const tabs = [
    { label: 'Schedule Availability', value: 'schedule-availability' },
    { label: 'Date Overrides', value: 'date-overrides' },
    { label: 'Calendar Preferences', value: 'calendar-preferences' }
  ]

  return (
    <AdasContainer maxWidth='lg'>
      <AdasPaper
        sx={{
          padding: '1.25rem 1.25rem',
          marginTop: '1.25rem',
          backgroundColor: 'white',
          marginBottom: '40px'
        }}
      >
        {!showDefaultPage && (
          <>
            <AdasBox
              sx={{
                marginBottom: '2rem',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', md: 'row' }
              }}
            >
              <AdasTypography sx={{ fontWeight: '600', fontSize: '18px' }} color={theme.typography.territoryColor}>
                ADAS Calibration Appointment
              </AdasTypography>
              <AdasBox
                sx={{
                  display: 'flex'
                }}
              >
                <AdasButton startIcon={<InsertLinkIcon />} onClick={() => setBookingLinkOpen(true)}>
                  View Booking LINK
                </AdasButton>
              </AdasBox>
            </AdasBox>
            <AdasBox>
              <AdasTabs
                tabs={tabs}
                value={calendarType}
                onChange={onTabChange}
                variant='fullWidth'
                scrollButtons='auto'
                sx={{ borderBottom: 1, borderColor: 'rgba(0, 0, 0, 0.06)', textTransform: 'capitalize' }}
              />
              {calendarType === 'schedule-availability' && (
                <ScheduleAvailability hasUnsavedChanges={hasUnsavedChanges} setHasUnsavedChanges={setHasUnsavedChanges} />
              )}
              {calendarType === 'date-overrides' && <DateOverrides />}
              {calendarType === 'calendar-preferences' && (
                <CalendarPreferences setBookingLinkOpen={setBookingLinkOpen} onSuccess={fetchOrganisationDetailsByCcId} />
              )}
            </AdasBox>
          </>
        )}

        {showDefaultPage && (
          <AdasBox
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            gap={2}
            sx={{ minHeight: '81vh', overflow: 'hidden' }}
          >
            {showOrganisationSetup && (
              <>
                <AdasBox>
                  <AdasTypography>Please setup the Scheduler for managing the appointments</AdasTypography>
                </AdasBox>
                <AdasButton variant='contained' onClick={handleSetupOrganisationConfirm}>
                  SETUP
                </AdasButton>
              </>
            )}
          </AdasBox>
        )}
      </AdasPaper>

      {bookingLinkOpen && (
        <AdasPaper elevation={0} sx={{ padding: '1.25rem', marginTop: '1.25rem', marginBottom: '20px' }}>
          <AdasDrawer anchor={'right'} open={bookingLinkOpen} onClose={() => setBookingLinkOpen(false)}>
            <AdasBox sx={{ width: '400px' }}>
              <AdasBox sx={{ margin: '25px 24px' }}>
                <span>Booking Link</span>
                <CloseIcon onClick={() => setBookingLinkOpen(false)} sx={{ float: 'right' }} fontSize='small'></CloseIcon>
              </AdasBox>
              <BookingLink />
            </AdasBox>
          </AdasDrawer>
        </AdasPaper>
      )}

      {openConfirmationDialog && (
        <AdsCustomModal
          title='Unsaved Changes'
          open={openConfirmationDialog}
          onClose={handleUnsavedChangesConfirm}
          description='You have unsaved changes. Do you want to leave without saving?'
          successButtonTitle='Leave'
        />
      )}
    </AdasContainer>
  )
}
