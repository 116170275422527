import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { AddCircleOutline } from '@mui/icons-material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import {
  AdasAlert,
  AdasBox,
  AdasCard,
  AdasTypography,
  // AdasTextField,
  AdasButton,
  AdasGrid,
  AdasFormControlLabel,
  AdasFormGroup,
  AdasCheckbox,
  AdasTimePicker,
  AdasDatePicker
} from '@components/wrapper-components'
import theme from 'theme/theme'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { StickyFooter } from '@components/sticky-footer'
import { DATE_FORMATS } from 'constants'
import { AdsCustomModal } from '@components/wrapper-components'
import { useCenterStore } from '@caradasstore/CenterStore'
import { useUserStore, useOrganisationStore } from '@caradasstore'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { createDateOverrides } from '../../../api/api'
import { Logger } from '../../../logger'
import { formatDate } from '../../../utils/utils'
import 'dayjs/locale/en-gb'

const AvailabilityInput = ({ index, availability, onChange, onRemove, error }) => {
  const timePickerProps = (hasError) => ({
    InputLabelProps: { shrink: true },
    inputProps: {
      min: '00:00',
      max: '23:59',
      step: 900,

      style: { padding: '12px' }
    },
    variant: 'outlined',
    error: hasError
  })

  return (
    <AdasGrid container spacing={1} alignItems='center' mb={3}>
      <AdasGrid item xs={5}>
        {/* <AdasTextField
          type='time'
          value={availability.start}
          onChange={(e) => onChange(index, 'start', e.target.value)}
          fullWidth
          minutesStep={15} // Set the minute step to 15
          {...timePickerProps(error)}
        /> */}
        <AdasTimePicker
          availability={availability.start}
          type={'start'}
          index={index}
          ampm={true}
          minutesStep={15}
          onChange={onChange}
          {...timePickerProps(error)}
        />
      </AdasGrid>
      <AdasGrid item xs={1}>
        <AdasTypography align='center' fontSize='small' color={theme.typography.shaded}>
          To {availability.error}
        </AdasTypography>
      </AdasGrid>
      <AdasGrid item xs={5}>
        {/* <AdasTextField
          type='time'
          value={availability.end}
          onChange={(e) => onChange(index, 'end', e.target.value)}
          fullWidth
          {...timePickerProps(error)}
        /> */}
        <AdasTimePicker
          availability={availability.end}
          type={'end'}
          index={index}
          onChange={onChange}
          ampm={true}
          minutesStep={15}
          {...timePickerProps(error)}
        />
      </AdasGrid>
      <AdasGrid item xs={0.1} pl={-1.5} ml={-1.5}>
        <AdasButton startIcon={<HighlightOffIcon />} sx={{ color: theme.palette.info.main }} onClick={() => onRemove(index)} />
      </AdasGrid>
    </AdasGrid>
  )
}
AvailabilityInput.propTypes = {
  index: PropTypes.number.isRequired,
  availability: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired
  }),
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
}

const AvailabilityForm = (props) => {
  const { setIsAddDateOpen, dates, onSuccess } = props
  const [selectedDate, setSelectedDate] = useState(dayjs())
  const [availabilities, setAvailabilities] = useState([{ start: '08:00', end: '17:00' }])
  const [isDayUnavailable, setIsDayUnavailable] = useState(false)
  const [errors, setErrors] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [open, setOpen] = useState(false)
  const ccId = useCenterStore((store) => store.ccId)
  const currentUser = useUserStore((store) => store.currentUser)
  const setLoading = useLoadingStore((store) => store.setLoading)
  const organisationId = useOrganisationStore((store) => store.organisationId)

  const handleAvailabilityChange = (index, key, value) => {
    console.log(index, key, value)
    const newAvailabilities = availabilities.map((availability, i) => (i === index ? { ...availability, [key]: value } : availability))

    setAvailabilities(newAvailabilities)
    validateAvailabilities(newAvailabilities, index)
  }

  const handleRemoveAvailability = (indexToRemove) => {
    const updatedAvailabilities = availabilities.filter((_, index) => index !== indexToRemove)
    validateAvailabilities(updatedAvailabilities)
    setAvailabilities(updatedAvailabilities)
  }

  const handleAddAvailability = () => {
    if (!availabilities || availabilities.length === 0) {
      setAvailabilities([{ start: '08:00', end: '17:00' }])
      return
    }
    const lastSlotEnd = availabilities.length > 0 ? availabilities[availabilities.length - 1].end : '08:00'

    const [lastEndHour, lastEndMinute] = lastSlotEnd.split(':').map(Number)

    let newStartHour = lastEndHour + 1
    const newStartMinute = lastEndMinute

    let formattedEndTime
    if (newStartHour >= 24) {
      formattedEndTime = '23:59'
      newStartHour = '23:59'
    } else {
      formattedEndTime = `${(newStartHour + 1).toString().padStart(2, '0')}:${newStartMinute.toString().padStart(2, '0')}`
    }

    const formattedStartTime = `${newStartHour.toString().padStart(2, '0')}:${newStartMinute.toString().padStart(2, '0')}`

    setAvailabilities([...availabilities, { start: formattedStartTime, end: formattedEndTime, error: false }])
  }

  const handleDayUnavailableChange = () => {
    setIsDayUnavailable(!isDayUnavailable)
  }

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate)
    if (dates) {
      const foundDate = dates.find(
        (date) => formatDate(date.overrides_date, DATE_FORMATS.ISO_DATE_STRING) === formatDate(newDate, DATE_FORMATS.ISO_DATE_STRING)
      )
      console.log(foundDate)
      if (foundDate && foundDate.slots) {
        setAvailabilities(foundDate.slots)
      }
    }
  }

  const validateAvailabilities = (availabilities, changedIndex = null) => {
    const errors = availabilities.map(() => false)

    const isOverlapping = (slot1, slot2) => slot1.start < slot2.end && slot1.end > slot2.start

    if (changedIndex === null) {
      availabilities.forEach((slot, index) => {
        if (index > 0 && isOverlapping(slot, availabilities[index - 1])) {
          errors[index] = true
        }
        if (index < availabilities.length - 1 && isOverlapping(slot, availabilities[index + 1])) {
          errors[index] = true
        }
      })
    } else {
      const changedSlot = availabilities[changedIndex]

      if (changedIndex > 0 && isOverlapping(changedSlot, availabilities[changedIndex - 1])) {
        errors[changedIndex] = true
      } else {
        errors[changedIndex] = errors[changedIndex] || false
      }

      if (changedIndex < availabilities.length - 1 && isOverlapping(changedSlot, availabilities[changedIndex + 1])) {
        errors[changedIndex] = true
      } else {
        errors[changedIndex] = errors[changedIndex] || false
      }
    }
    availabilities.forEach((slot, index) => {
      if (slot.start >= slot.end) {
        errors[index] = true
      }
    })
    const hasErrors = errors.some((error) => error === true)
    if (!hasErrors) {
      setShowAlert(false)
    }
    setErrors(errors)
  }

  useEffect(() => {
    validateAvailabilities(availabilities)
  }, [availabilities])

  const handleClose = (event, value) => {
    if (value && value == 'backdropClick') return
    setOpen(false)
  }

  const getAvailabilitiesPostData = (isDayUnavailable, availabilitiesData) => {
    if (isDayUnavailable) {
      return {
        ccId,
        organisation_id: organisationId,
        availabilities: [
          {
            start_time: '00:00',
            end_time: '00:00',
            organisation_id: organisationId,
            overrides_date: selectedDate.format('YYYY-MM-DD'),
            is_unavailable: isDayUnavailable,
            is_active: true,
            updated_by: currentUser.id
          }
        ]
      }
    }
    return {
      ccId,
      organisation_id: organisationId,
      availabilities: availabilitiesData.map((availability) => ({
        start_time: availability?.start || null,
        end_time: availability?.end || null,
        organisation_id: organisationId,
        overrides_date: selectedDate.format('YYYY-MM-DD'),
        is_unavailable: isDayUnavailable,
        is_active: true,
        updated_by: currentUser.id
      }))
    }
  }

  const handleSubmit = async () => {
    validateAvailabilities(availabilities)
    const hasErrors = errors.some((error) => error === true)
    if (!isDayUnavailable && hasErrors) {
      setShowAlert(true)
      return
    }

    setLoading(true)
    try {
      const data = getAvailabilitiesPostData(isDayUnavailable, availabilities)
      const response = await createDateOverrides(data)
      if (response?.data) {
        onSuccess()
        setIsAddDateOpen(false)
      }
    } catch (error) {
      Logger.error({
        message: error,
        payload: { availabilities, path: 'handleSubmit' }
      })
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = (event, value) => {
    setIsAddDateOpen(false)
  }

  return (
    <AdasCard sx={{ margin: '15px 20px', padding: '15px 15px', backgroundColor: '#FDFBFF' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AdasBox minHeight='75vh'>
          <AdasBox mb={3}>
            <AdasDatePicker
              label='Add Date'
              variant='outlined'
              name='add_date'
              value={selectedDate.format(DATE_FORMATS.ISO_DATE_STRING)}
              format={DATE_FORMATS.ISO_DATE_STRING}
              disablePast={true}
              onChange={handleDateChange}
            />
          </AdasBox>
          <AdasBox mb={3}>
            <AdasFormGroup>
              <AdasFormControlLabel
                control={
                  <AdasCheckbox
                    checked={isDayUnavailable}
                    onChange={handleDayUnavailableChange}
                    name='Make Day Unavailable'
                    sx={{ padding: '0', mx: '10px' }}
                  />
                }
                label={
                  <AdasTypography color={theme.typography.shaded} variant='body2'>
                    {'Make Day Unavailable'}
                  </AdasTypography>
                }
              />
            </AdasFormGroup>
          </AdasBox>
          {!isDayUnavailable && (
            <AdasBox>
              <AdasTypography color={theme.typography.shaded} variant='subtitle2' mb={2}>
                Availability
              </AdasTypography>
              {availabilities.map((availability, index) => (
                <AvailabilityInput
                  key={index}
                  index={index}
                  availability={availability}
                  onChange={handleAvailabilityChange}
                  onRemove={handleRemoveAvailability}
                  error={errors[index]}
                />
              ))}
              <AdasButton
                startIcon={<AddCircleOutline />}
                onClick={handleAddAvailability}
                sx={{ mt: 2 }}
                disabled={availabilities && availabilities.length >= 2}
              >
                Add Availability
              </AdasButton>
              {showAlert && (
                <AdasAlert severity='error'>
                  Some time slots have errors. Ensure that all slots have valid start and end times, and that no slots overlap with each
                  other.
                </AdasAlert>
              )}
            </AdasBox>
          )}
        </AdasBox>
        <StickyFooter>
          <AdasBox mt={2} display='flex' justifyContent='end' gap={3}>
            <AdasButton variant='outlined' onClick={handleCancel}>
              CANCEL
            </AdasButton>
            <AdasButton
              variant='contained'
              color='primary'
              onClick={handleSubmit}
              disabled={!isDayUnavailable && availabilities.length === 0}
            >
              ADD
            </AdasButton>
          </AdasBox>
        </StickyFooter>
      </LocalizationProvider>
      <AdsCustomModal
        title='Confirm Override'
        open={open}
        onClose={handleClose}
        description={'Please check for existing appointments on this date, reschedule them accordingly.'}
        successButtonTitle={'Confirm'}
        cancelButtonTitle={'GO Back'}
        cancelButtonConfig={{
          variant: 'outlined'
        }}
      />
    </AdasCard>
  )
}

export default AvailabilityForm
