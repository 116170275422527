import {
  AdasBox,
  AdasTextField,
  AdasTypography,
  AdasFormControl,
  AdasSelect,
  AdasCheckbox,
  AdasPhoneField,
  AdasInputLabel
} from '@components/wrapper-components'
// import {   AdasSearchMenu,  AdasRadioGroup,AdasPhoneField,AdasFormControlLabel, AdasFormGroup } from '@components/wrapper-components'
import { AccordionDetails } from '@mui/material'
import { useState, useEffect, useCallback, useMemo } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FormControlLabel } from '@mui/material'
import { UsaStates } from 'usa-states'
import { Accordion, AccordionSummary } from './helper'
import { DATE_FORMATS, TEXT_CONSTANTS, MAX_NOTES_LENGTH } from 'constants'
import { TextField } from '@mui/material'
import { getClients, getTimeSlots, getYears, getMakes, getModels } from 'api/api'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { useOrganisationStore } from '@caradasstore/OrganisationStore'
import { useCenterStore } from '@caradasstore/CenterStore'
import { useAvailabilityStore } from '@caradasstore/AvailabilityStore'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Logger } from 'logger'
import { VinInput } from '@components/vin'
import { AppointmentAttachment } from './AppointmentAttachment'
import { SchedulerDatePicker } from '../SchedulerDatePicker'
import { sortByName } from '../../../utils/utils'
import { formatDate } from '../../../utils/utils'
dayjs.extend(utc)

export const AppointmentForm = ({
  formValues,
  handleChange,
  setFormValues,
  formErrors,
  handleFileDelete,
  formValid,
  setAvailabilities,
  newTime,
  formattedDate,
  setFormValid,
  validationMap
}) => {
  const usStates = new UsaStates()
  const [timeSlots, setTimeSlots] = useState(null) // Initialize to null
  const setLoading = useLoadingStore((store) => store.setLoading)
  const organisationId = useOrganisationStore((store) => store.organisationId)
  const organisationDetail = useOrganisationStore((store) => store.organisationDetail)
  const availabilityDetail = useAvailabilityStore((state) => state.availabilityDetail)
  const ccId = useCenterStore((store) => store.ccId)
  const [years, setYears] = useState([])
  const [makes, setMakes] = useState([])
  const [models, setModels] = useState([])
  const [clients, setClients] = useState([])

  //const [sameAddress, setSameAddress] = useState(false)
  //const timezoneOffset = organisationDetail.timezone_at_value || 'UTC' // Assuming the value is in format "-09:00"

  const getMaxDateFromOrganisation = (minutes) => {
    const days = dayjs.duration(minutes, 'minutes').asDays()
    return dayjs()
      .add(Math.floor(days) - 1, 'days')
      .endOf('day')
  }

  // Usage
  const maxDateFromOrganisation = getMaxDateFromOrganisation(organisationDetail?.max_time)

  const formatTimeOrReturn = (time, toFormat) => {
    return time ? dayjs.utc(time, 'HH:mm').format(toFormat) : 'No time slots are available'
  }

  const convertMakesDataToIdValueArray = (data) => {
    return data.map((option) => {
      return { id: option.make, makeCode: option.makeCode, value: option.make }
    })
  }

  const convertModelDataToIdValueArray = (data) => {
    return data.map((option) => {
      return { id: option.description, value: option.description }
    })
  }

  const fetchVehicleData = async (year, makeCode) => {
    try {
      setLoading(true)

      // Fetch makes if a year is provided, and find the selected make if makeCode is available
      const makesResponse = year ? await getMakes({ year }) : { data: { makes: [] } }

      const makes = convertMakesDataToIdValueArray(makesResponse?.data?.makes || [])
      setMakes(makes)

      // Find the selected make using makeCode
      const selectedMake = makeCode ? makes.find((make) => make.value === makeCode) : null

      // Fetch models only if year and selectedMake are available
      const modelsResponse = year && selectedMake ? await getModels({ year, makeCode: selectedMake.makeCode }) : { data: { models: [] } }

      setModels(convertModelDataToIdValueArray(modelsResponse?.data?.models || []))

      // Fetch years data regardless of year/make selection
      const yearsResponse = await getYears()
      setYears(yearsResponse?.data?.years || [])
    } catch (error) {
      Logger.error({
        message: error,
        payload: { year, makeCode, path: 'fetchVehicleData' }
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!formValues.useVin) {
      fetchVehicleData(formValues.vehicleYear, formValues.vehicleMake ? formValues.vehicleMake : null)
    }
  }, [formValues.useVin, formValues.vehicleYear, formValues.vehicleMake])

  const handleVehicleFormChange = async (e) => {
    const { name, value } = e.target

    // Update form values based on the field being changed
    setFormValues((prevState) => ({
      ...prevState,
      vehicleMake: name === 'vehicleYear' ? '' : prevState.vehicleMake,
      vehicleModel: name === 'vehicleMake' ? '' : prevState.vehicleModel
    }))

    // Handle form changes based on which field is being updated
    if (name === 'vehicleYear') {
      await fetchVehicleData(value, null) // Fetch makes based on the selected year
    } else if (name === 'vehicleMake') {
      const selectedMake = makes.find((make) => make.value === value)
      if (selectedMake) {
        await fetchVehicleData(formValues?.vehicleYear, selectedMake.makeCode) // Fetch models based on year and selected make
      }
    }

    handleChange(e) // Call the generic handle change logic
  }

  useEffect(() => {
    if (formValues.appointmentDate) {
      const foundDate = Array.isArray(availabilityDetail)
        ? availabilityDetail.find(
            (date) =>
              formatDate(date.overrides_date, DATE_FORMATS.ISO_DATE_STRING) ===
              formatDate(formValues.appointmentDate, DATE_FORMATS.ISO_DATE_STRING)
          )
        : null // Handle the case when availabilityDetail is not an array

      // If you receive new data from `foundDate`, update the state
      if (foundDate && foundDate.slots) {
        const formattedAvailabilities = foundDate?.slots.map((slot) => ({
          ...slot,
          overrides_date: foundDate.overrides_date // Add overrides_date to each slot
        }))

        setAvailabilities({ overrides_date: foundDate.overrides_date, slots: formattedAvailabilities })
      } else {
        setAvailabilities({
          overrides_date: '', // Reset overrides_date to empty string
          slots: [] // Reset slots to an empty array
        })
      }

      fetchTimeSlots(formValues.appointmentDate)
    } else {
      setTimeSlots(null)
    }
  }, [formValues.appointmentDate])

  const updateVehicleInfo = useCallback((props) => {
    if (!props) {
      console.error('Vehicle info update failed: No vehicle data provided.')
      return
    }

    const { vin } = props

    // If VIN is empty, clear vehicle fields
    if (!vin) {
      setFormValues((prevState) => ({
        ...prevState,
        vehicleYear: '',
        vehicleMake: '',
        vehicleModel: '',
        vin: ''
      }))
      return
    }

    const { year, make, model } = props.data

    setFormValues((prevState) => ({
      ...prevState,
      vehicleYear: year || prevState.vehicleYear,
      vehicleMake: make || prevState.vehicleMake,
      vehicleModel: model || prevState.vehicleModel,
      vin: vin || prevState.vin
    }))
  }, [])

  useEffect(() => {
    if (formValues.vin && formValues.useVin) {
      // Simulate fetching vehicle details by VIN (replace with real API call)
      updateVehicleInfo()
    }
  }, [formValues.vin, formValues.useVin, updateVehicleInfo])

  const resetVehicleValues = useCallback(() => {
    setFormValues((prevState) => ({
      ...prevState,
      vehicleYear: '',
      vehicleMake: '',
      vehicleModel: '',
      vin: ''
    }))
    setMakes([])
    setModels([])
  }, [setFormValues])
  const vinChangeHandle = useCallback((e) => {
    resetVehicleValues()
    handleChange(e)
  }, [])

  const useExistingClientChangeHandle = useCallback((e) => {
    if (!formValues?.useExistingClient) {
      setFormValues((prevState) => ({
        ...prevState,
        businessName: '',
        primaryEmail: '',
        businessId: '',
        businessPhone: ''
      }))
    } else {
      setFormValues((prevState) => ({
        ...prevState,
        businessName: '',
        primaryEmail: '',
        businessId: '',
        businessPhone: ''
      }))
    }
    handleChange(e)
  }, [])

  const existingBusinessNameChangeHandle = (e) => {
    const { value } = e.target
    const client = clients?.find((client) => client.id === value)
    setFormValues((prevState) => ({
      ...prevState,
      businessName: client?.name || '',
      primaryEmail: client?.email || '',
      businessPhone: client?.phone || ''
    }))
  }

  const fetchClient = async () => {
    const clientListResponse = await getClients({ ccid: ccId })
    if (clientListResponse.status === 200) {
      setClients(clientListResponse.data.sort(sortByName))
    }
  }

  useEffect(() => {
    fetchClient()
  }, [ccId])

  useEffect(() => {
    if (clients.length > 0 && formValues.businessName && formValues.primaryEmail) {
      const client = clients?.find(
        (client) =>
          client.name?.toLowerCase() === formValues.businessName?.toLowerCase() &&
          client.email?.toLowerCase() === formValues.primaryEmail?.toLowerCase() &&
          client.phone?.toLowerCase() === formValues.businessPhone?.toLowerCase()
      )
      setFormValues((prevState) => ({
        ...prevState,
        useExistingClient: client ? true : false,
        businessId: client?.id || ''
      }))
    }
  }, [clients, formValues.businessName, formValues.primaryEmail])

  const timeSlotChangeHandler = useCallback((e) => {
    if (e.target.value === 'default') {
      return
    }
    handleChange(e)
  }, [])

  const fetchTimeSlots = async (selectedDate) => {
    setLoading(true)
    const formattedDate = dayjs(selectedDate).format(DATE_FORMATS.ISO_DATE)
    const params = {
      ccId: ccId,
      organisationId: organisationId,
      date: formattedDate
    }
    try {
      const response = await getTimeSlots(params)
      setTimeSlots(response.data.length ? response.data : []) // Set empty array if no slots
    } catch (error) {
      Logger.error({
        message: error,
        payload: { params, path: 'fetchTimeSlots' }
      })
    } finally {
      setLoading(false)
    }
  }

  // const generateAppointmentTimeSlotOptions = (timeSlots, formValues) => {
  //   if ((!timeSlots || timeSlots.length === 0) && !formValues.appointmentTime) {
  //     return [{ id: 'default', value: timeSlots === null ? 'Please select date first' : 'No time slots are available' }]
  //   }

  //   if (formValues.appointmentTime) {
  //     if (!timeSlots) {
  //       timeSlots = [formValues.appointmentTime]
  //     } else {
  //       const appointmentTimeExists = timeSlots.some((slot) => slot === formValues.appointmentTime)
  //       if (!appointmentTimeExists) {
  //         timeSlots.push(formValues.appointmentTime)
  //       }
  //     }
  //   }

  //   if (timeSlots?.length > 0) {
  //     return timeSlots.map((slot) => ({
  //       id: formatTimeOrReturn(slot, 'HH:mm'),
  //       value: formatTimeOrReturn(slot, DATE_FORMATS.ISO_TIME_STRING)
  //     }))
  //   }

  //   return []
  // }

  const generateAppointmentTimeSlotOptions = (timeSlots, formValues, newTime) => {
    if (!timeSlots || timeSlots.length === 0) {
      return [{ id: 'default', value: timeSlots === null ? 'Please select date first' : 'No time slots are available' }]
    }

    const uniqueTimeSlots = new Set(timeSlots)

    if (newTime && formValues.appointmentDate && dayjs(formValues.appointmentDate).isSame(dayjs(formattedDate), 'day')) {
      uniqueTimeSlots.add(newTime) // Add newTime only if dates match
    }

    return Array.from(uniqueTimeSlots).map((slot) => ({
      id: formatTimeOrReturn(slot, 'HH:mm'),
      value: formatTimeOrReturn(slot, DATE_FORMATS.ISO_TIME_STRING)
    }))
  }
  const timeSlotOptions = useMemo(() => {
    return generateAppointmentTimeSlotOptions(timeSlots, formValues, newTime)
  }, [timeSlots, formValues, newTime])

  // const handlePickupContactChange = (e) => {
  //   const { name, value } = e.target
  //   setFormValues((prevValues) => ({
  //     ...prevValues,
  //     pickupContact: {
  //       ...prevValues.pickupContact,
  //       [name]: value
  //     }
  //   }))
  //   setFormValid((prev) => ({
  //     ...prev,
  //     pickupContact: {
  //       ...prev.pickupContact,
  //       ...(name in validationMap.pickupContact ? { [name]: validationMap.pickupContact[name](value.trim()) } : {})
  //     }
  //   }))
  // }

  // const handleSameAddressChange = (event) => {
  //   const { checked } = event.target
  //   setSameAddress(checked)

  //   if (checked) {
  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       pickupContact: {
  //         fullName: prevValues.fullName,
  //         phone: prevValues.phone
  //       }
  //     }))
  //   } else {
  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       pickupContact: {
  //         fullName: '',
  //         phone: ''
  //       }
  //     }))
  //   }
  // }
  return (
    <>
      <>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='date-time-content' id='date-time-header'>
            <AdasTypography variant='subtitle2'>Date & Time</AdasTypography>
          </AccordionSummary>
          <AccordionDetails>
            <AdasBox>
              <SchedulerDatePicker
                fullWidth
                label='Date'
                variant='outlined'
                name='appointmentDate'
                InputLabelProps={{ shrink: true }}
                value={formValues.appointmentDate}
                renderInput={(params) => <TextField {...params} />}
                format={DATE_FORMATS.ISO_DATE_STRING}
                onChange={handleChange}
                sx={{ mb: 2, width: '100%' }}
                minDate={dayjs()}
                maxDate={maxDateFromOrganisation}
              />

              <AdasFormControl fullWidth sx={{ mb: 2 }}>
                <AdasInputLabel id='appointment-time-label'>Time</AdasInputLabel>
                <AdasSelect
                  labelId='appointment-time-label'
                  name='appointmentTime'
                  // value={formatTimeOrReturn(formValues.appointmentTime)}
                  value={formValues.appointmentTime}
                  onChange={timeSlotChangeHandler}
                  label='Time'
                  id='selectItems'
                  options={timeSlotOptions}
                  disabled={timeSlots === null || ''} // Disable if loading or no date
                />
              </AdasFormControl>
            </AdasBox>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='contact-info-content' id='contact-info-header'>
            <AdasTypography variant='subtitle2'>Contact Info</AdasTypography>
          </AccordionSummary>
          <AccordionDetails>
            <>
              <AdasBox sx={{ mb: 2 }}>
                <AdasTextField
                  fullWidth
                  name='fullName'
                  label='Full Name'
                  value={formValues?.fullName}
                  onChange={handleChange}
                  error={!formValid.fullName}
                  helperText={!formValid.fullName ? TEXT_CONSTANTS.INVALID_NAME : ''}
                  inputProps={{ maxLength: 50 }}
                />
              </AdasBox>
              <AdasBox sx={{ mb: 2 }}>
                <FormControlLabel
                  control={
                    <AdasCheckbox
                      checked={formValues?.useExistingClient}
                      onChange={useExistingClientChangeHandle}
                      name='useExistingClient'
                    />
                  }
                  label='Existing Client'
                />
              </AdasBox>
              {formValues.useExistingClient && (
                <AdasBox sx={{ mb: 2 }}>
                  <AdasFormControl fullWidth>
                    <AdasInputLabel id='business-id-label'>Business Name</AdasInputLabel>
                    <AdasSelect
                      label='Business Name'
                      labelId='business-id-label'
                      id='business-name-label'
                      name='businessId'
                      value={formValues.businessId}
                      onChange={existingBusinessNameChangeHandle}
                      options={clients || []}
                    />
                  </AdasFormControl>
                </AdasBox>
              )}
              {!formValues.useExistingClient && (
                <AdasBox sx={{ mb: 2 }}>
                  <AdasTextField
                    fullWidth
                    name='businessName'
                    label='Business Name'
                    value={formValues.businessName}
                    onChange={handleChange}
                    inputProps={{ maxLength: 50 }}
                  />
                </AdasBox>
              )}
              <AdasBox sx={{ mb: 2 }}>
                <AdasPhoneField
                  fullWidth
                  name='businessPhone'
                  label='Business Phone'
                  value={formValues.businessPhone}
                  onChange={handleChange}
                  disabled={formValues.useExistingClient}
                  isValid={formValid.businessPhone}
                />
              </AdasBox>
              <AdasBox sx={{ mb: 2 }}>
                <AdasPhoneField
                  fullWidth
                  name='phone'
                  label='Mobile Phone (optional)'
                  value={formValues.phone}
                  onChange={handleChange}
                  isValid={formValid.phone}
                />
              </AdasBox>

              <AdasBox sx={{ mb: 2 }}>
                <AdasTextField
                  fullWidth
                  name='primaryEmail'
                  label='Primary Email'
                  value={formValues.primaryEmail}
                  onChange={handleChange}
                  disabled={formValues.useExistingClient}
                  error={!formValid.primaryEmail}
                  helperText={!formValid.primaryEmail ? TEXT_CONSTANTS.INVALID_EMAIL : ''}
                  inputProps={{ maxLength: 60 }}
                />
              </AdasBox>
              <AdasBox sx={{ mb: 2 }}>
                <AdasTextField
                  fullWidth
                  name='secondaryEmail'
                  label='Secondary Email (optional)'
                  value={formValues.secondaryEmail}
                  onChange={handleChange}
                  error={!formValid.secondaryEmail}
                  helperText={!formValid.secondaryEmail ? TEXT_CONSTANTS.INVALID_EMAIL : ''}
                  inputProps={{ maxLength: 60 }}
                />
              </AdasBox>
              <AdasBox sx={{ mb: 2 }}>
                <AdasTextField
                  fullWidth
                  name='referral'
                  label='Referral (optional)'
                  value={formValues.referral}
                  onChange={handleChange}
                  error={!formValid.referral}
                  helperText={!formValid.referral ? TEXT_CONSTANTS.INAVLID_ALPHANUMERIC : ''}
                  inputProps={{ maxLength: 50 }}
                />
              </AdasBox>
              {/* <AdasTypography variant='body1' gutterBottom>
                Pickup/deliver the vehicle{' '}
              </AdasTypography>
              <AdasBox sx={{ mb: 2 }}>
                <AdasFormControl>
                  <AdasRadioGroup
                    row
                    options={[
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' }
                    ]}
                    aria-labelledby='type-group'
                    name='pickDeliver'
                    value={formValues.pickDeliver}
                    onChange={handleChange}
                  />
                </AdasFormControl>
              </AdasBox>
              <AdasTypography variant='body1' gutterBottom mb={2}>
                Address details are optional
              </AdasTypography>
              <AdasBox sx={{ mb: 2 }}>
                <AdasTextField
                  fullWidth
                  name='address'
                  label='Address'
                  value={formValues.address}
                  onChange={handleChange}
                  error={!formValid.address}
                  helperText={!formValid.address ? TEXT_CONSTANTS.INAVLID_ALPHANUMERIC : ''}
                  inputProps={{ maxLength: 50 }}
                />
              </AdasBox>
              <AdasBox sx={{ mb: 2 }}>
                <AdasPhoneField
                  fullWidth
                  name='phone'
                  label='Phone'
                  value={formValues.phone}
                  onChange={handleChange}
                  isValid={formValid.phone}
                />
              </AdasBox>
              <AdasBox sx={{ mb: 2 }}>
                <AdasTextField
                  fullWidth
                  name='city'
                  label='City'
                  value={formValues.city}
                  onChange={handleChange}
                  error={!formValid.city}
                  helperText={!formValid.city ? TEXT_CONSTANTS.INAVLID_ALPHANUMERIC : ''}
                  inputProps={{ maxLength: 50 }}
                />
              </AdasBox>
              <AdasSearchMenu
                fullWidth
                id='select-state'
                select
                label='State *'
                variant='outlined'
                onChange={handleChange}
                sx={{ mb: 2 }}
                name='state'
                value={formValues.state}
                options={usStates.states.map((state) => ({
                  value: state.name, // Use state.name instead of state.abbreviation
                  name: state.name
                }))}
              />
              <AdasBox sx={{ mb: 2 }}>
                <AdasTextField
                  fullWidth
                  name='zipCode'
                  type='text'
                  label='ZIP Code'
                  value={formValues.zipCode}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/\D/g, '')
                    handleChange({
                      target: {
                        name: 'zipCode',
                        value: newValue.slice(0, 5)
                      }
                    })
                  }}
                  error={!formValid.zipCode}
                  helperText={!formValid.zipCode ? TEXT_CONSTANTS.INVALID_ZIP : ''}
                  inputProps={{ maxLength: 5 }}
                />
              </AdasBox> */}
              {/* <AdasTypography variant='body1' gutterBottom mb={2}>
                Pickup details are optional
              </AdasTypography>
              <AdasBox sx={{ mb: 2 }}>
                <AdasFormGroup>
                  <AdasFormControlLabel
                    control={<AdasCheckbox checked={sameAddress} onChange={handleSameAddressChange} name='sameAddress' />}
                    label='Same as address'
                  />
                </AdasFormGroup>
              </AdasBox>
              <AdasBox sx={{ mb: 2 }}>
                <AdasTextField
                  fullWidth
                  name='fullName'
                  label='Pickup Name'
                  value={formValues.pickupContact.fullName}
                  onChange={handlePickupContactChange}
                  disabled={sameAddress}
                  error={!formValid.pickupContact.fullName}
                  helperText={!formValid.pickupContact.fullName ? TEXT_CONSTANTS.INVALID_NAME : ''}
                  inputProps={{ maxLength: 50 }}
                />
              </AdasBox>

              <AdasBox sx={{ mb: 2 }}>
                <AdasPhoneField
                  fullWidth
                  name='phone'
                  label='Pickup Phone Number'
                  value={formValues.pickupContact.phone}
                  onChange={handlePickupContactChange}
                  disabled={sameAddress}
                  isValid={formValid.pickupContact.phone}
                />
              </AdasBox> */}
            </>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='vehicle-info-content' id='vehicle-info-header'>
            <AdasTypography variant='subtitle2'>Vehicle Info</AdasTypography>
          </AccordionSummary>
          <AccordionDetails>
            <>
              <AdasBox sx={{ mb: 2 }}>
                <FormControlLabel
                  control={<AdasCheckbox checked={formValues.useVin} onChange={vinChangeHandle} name='useVin' />}
                  label='Use VIN'
                />
              </AdasBox>
              {formValues.useVin && (
                <AdasBox sx={{ mb: 2 }}>
                  <VinInput updateVehicleInfo={updateVehicleInfo} prepopulateVin={formValues.vin} isScan={false} />
                </AdasBox>
              )}

              <AdasBox sx={{ mb: 2 }}>
                {formValues.useVin && (
                  <AdasTextField
                    fullWidth
                    name='vehicleYear'
                    label='Year'
                    value={formValues.vehicleYear}
                    onChange={handleChange}
                    disabled={formValues.useVin}
                  />
                )}
                {!formValues.useVin && (
                  <AdasFormControl fullWidth sx={{ mb: 2 }}>
                    <AdasInputLabel id='vehicle-year-label'>Year</AdasInputLabel>
                    <AdasSelect
                      fullWidth
                      id='vehicleYear'
                      labelId='vehicle-year-label'
                      name='vehicleYear'
                      label='Year'
                      value={formValues?.vehicleYear}
                      onChange={handleVehicleFormChange}
                      options={years}
                    />
                  </AdasFormControl>
                )}
              </AdasBox>
              <AdasBox sx={{ mb: 2 }}>
                {formValues.useVin && (
                  <AdasTextField
                    fullWidth
                    name='vehicleMake'
                    label='Make'
                    value={formValues.vehicleMake}
                    onChange={handleChange}
                    disabled={formValues.useVin}
                  />
                )}
                {!formValues.useVin && (
                  <AdasFormControl fullWidth sx={{ mb: 2 }}>
                    <AdasInputLabel id='vehicle-Make-label'>Make</AdasInputLabel>
                    <AdasSelect
                      fullWidth
                      id='vehicleMake'
                      labelId='vehicle-Make-label'
                      name='vehicleMake'
                      label='Make'
                      value={formValues?.vehicleMake}
                      onChange={handleVehicleFormChange}
                      options={makes}
                    />
                  </AdasFormControl>
                )}
              </AdasBox>
              <AdasBox sx={{ mb: 2 }}>
                {formValues.useVin && (
                  <AdasTextField
                    fullWidth
                    name='vehicleModel'
                    label='Model'
                    value={formValues.vehicleModel}
                    onChange={handleChange}
                    disabled={formValues.useVin}
                  />
                )}
                {!formValues.useVin && (
                  <AdasFormControl fullWidth sx={{ mb: 2 }}>
                    <AdasInputLabel id='vehicle-Model-label'>Model</AdasInputLabel>
                    <AdasSelect
                      fullWidth
                      id='vehicleModel'
                      labelId='vehicle-Model-label'
                      name='vehicleModel'
                      label='Model'
                      value={formValues?.vehicleModel}
                      onChange={handleVehicleFormChange}
                      options={models}
                    />
                  </AdasFormControl>
                )}
              </AdasBox>
              <AdasBox sx={{ mb: 2 }}>
                <AdasTextField
                  fullWidth
                  name='aro'
                  label='RO # (optional)'
                  value={formValues.aro}
                  onChange={handleChange}
                  error={!formValid.aro}
                  helperText={!formValid.aro ? TEXT_CONSTANTS.INAVLID_ALPHANUMERIC : ''}
                  inputProps={{ maxLength: 15 }}
                />
              </AdasBox>
              <AdasBox sx={{ mb: 2 }}>
                <AdasTextField
                  fullWidth
                  name='notes'
                  label='Additional Notes'
                  value={formValues.notes}
                  onChange={handleChange}
                  inputProps={{ maxLength: MAX_NOTES_LENGTH }}
                  helperText={`Maximum ${MAX_NOTES_LENGTH} characters (${MAX_NOTES_LENGTH - (formValues.notes?.length || 0)} remaining).`}
                />
              </AdasBox>
              <AdasBox sx={{ mb: 2 }}>
                <AppointmentAttachment
                  name='recentEstimate'
                  value={formValues.recentEstimate}
                  onChange={handleChange}
                  onDelete={handleFileDelete}
                  title='Please upload your most current estimate here.'
                  label='Upload Most Recent Estimate (Optional)'
                />
              </AdasBox>
            </>
          </AccordionDetails>
        </Accordion>
      </>
    </>
  )
}
