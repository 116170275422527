import { useCenterStore } from '@caradasstore/CenterStore'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { useOrganisationStore } from '@caradasstore/OrganisationStore'
import { useMessageStore } from '@caradasstore/MessageStore'
import { useUserStore } from '@caradasstore/UserStore'
import { StickyFooter } from '@components/sticky-footer'
import {
  AdasAccordionDetails,
  AdasBox,
  AdasButton,
  AdasCard,
  AdasDivider,
  AdasFormControl,
  AdasSelect,
  AdasStack,
  AdasTypography,
  AdsCustomModal
} from '@components/wrapper-components'
import { PictureAsPdf } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import { AccordionDetails } from '@mui/material'
import {
  cancelAppointment,
  createAppointmentEstimate,
  getAppointmentDetails,
  getAppointmentEstimate,
  getAssignee,
  updateAppointment,
  updateSchedularAssignee,
  updateSchedularStatus
} from 'api/api'
import { DATE_FORMATS, REDIRECT_URLS, MAX_FILE_SIZE_MB } from 'constants'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Logger } from 'logger'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import theme from 'theme/theme'
import { scrollPageTop } from 'utils/useScrollTop'
import { formatDate, formatExistingTimeOrReturn, validations, formatPhoneNumber } from 'utils/utils'
import { AppointmentForm } from './AppointmentForm'
import { AppointmentPayload } from './AppointmentPayload'
import { Accordion, AccordionSummary, schedularStatusStyles, statusOptions } from './helper'
import isEqual from 'lodash/isEqual'
import { AppointmentAttachment } from './AppointmentAttachment'
import { DataOverrideCard } from './DataOverrideCard'
import { useDeviceQueries } from '@hooks/useDeviceQueries'
dayjs.extend(utc)

const ALLOWED_FILE_TYPES = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/jpeg',
  'image/png'
]

export const AppointmentDetails = ({ selectedEventId, cancel, setRefresh }) => {
  const [editMode, setEditMode] = useState(false)
  const [open, setOpen] = useState(false)
  const [cancelAppointmentModal, setCancelAppointmentModal] = useState(false)
  const ccId = useCenterStore((store) => store.ccId)
  const setLoading = useLoadingStore((store) => store.setLoading)
  const addMessage = useMessageStore((store) => store.addMessage)
  const isLoading = useLoadingStore((store) => store.loading)
  const organisationDetail = useOrganisationStore((store) => store.organisationDetail)
  const history = useHistory()
  const currentUser = useUserStore((store) => store.currentUser)

  const [formValues, setFormValues] = useState({ recentEstimate: null })
  const [originalValues, setOriginalValues] = useState({ recentEstimate: null })
  const [availabilities, setAvailabilities] = useState({
    overrides_date: '', // You can provide a default date or empty string
    slots: [] // Default empty array for slots
  })

  const [formErrors, setFormErrors] = useState({
    recentEstimate: ''
    // ... other error fields
  })
  const { isMobile } = useDeviceQueries()
  const [formattedDate, setFormattedDate] = useState('')
  const [newTime, setNewTime] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true) // New state for submit button

  const [formValid, setFormValid] = useState({
    fullName: true,
    primaryEmail: true,
    secondaryEmail: true,
    phone: true,
    businessName: true,
    businessPhone: true,
    referral: true,
    aro: true
  })

  const validationMap = {
    fullName: validations.requiredAlphanumericText,
    primaryEmail: validations.email,
    secondaryEmail: validations.optionalEmail,
    businessPhone: validations.optionalPhone,
    phone: validations.optionalPhone,
    referral: validations.alphanumericText,
    aro: validations.alphanumericText
  }

  const hasChanges = () => {
    const { recentEstimate, businessId, useExistingClient, ...requiredFields } = formValues
    const {
      recentEstimate: originalEstimate,
      businessId: originalBussinessId,
      useExistingClient: duseExistingClient,
      ...originalFormValues
    } = originalValues
    return !isEqual(requiredFields, originalFormValues) || !isEqual(recentEstimate?.id, originalEstimate?.id)
  }

  useEffect(() => {
    const areRequiredFieldsFilled = () => {
      const { fullName, primaryEmail, appointmentDate, appointmentTime, vehicleYear, vehicleMake, vehicleModel, businessPhone } = formValues
      return fullName && primaryEmail && appointmentDate && appointmentTime && vehicleYear && vehicleMake && vehicleModel && businessPhone
    }

    const areAllFieldsValid = () => {
      const { fullName, primaryEmail, secondaryEmail, businessPhone, referral, aro } = formValid

      return fullName && primaryEmail && secondaryEmail && referral && aro && businessPhone
    }

    const isFormValid = () => {
      return areRequiredFieldsFilled() && areAllFieldsValid()
    }

    const canDisableSubmit = isLoading || !isFormValid() || (!hasChanges() && editMode)
    setIsSubmitDisabled(canDisableSubmit)
  }, [formValues])
  const [assigneeOptions, setAssigneeOptions] = useState([])

  useEffect(() => {
    fetchAssignees()
    fetchAppointmentDetails()
  }, [ccId])

  const fetchAssignees = async () => {
    try {
      const response = await getAssignee(ccId, organisationDetail.id)
      const formattedAssignees =
        response?.data?.map((assignee) => ({
          id: assignee.id,
          name: assignee.full_name
        })) || []
      const defaultAssignee = { id: 'null', name: 'Unassigned' }
      setAssigneeOptions([defaultAssignee, ...formattedAssignees])
    } catch (error) {
      console.error('Error fetching assignees:', error)
    }
  }

  const fetchAppointmentDetails = async () => {
    try {
      const [appointmentDetailsResponse, appointmentEstimateResponse] = await Promise.all([
        getAppointmentDetails(ccId, selectedEventId),
        getAppointmentEstimate(ccId, selectedEventId)
      ])
      const appointmentData = appointmentDetailsResponse?.data
      const timezoneOffset = appointmentData?.organisation.timezone_at || 'UTC' // Assuming the value is in format "-09:00"
      const { newDate, newTime, startTime, endTime } = formatExistingTimeOrReturn(
        appointmentData.appointment_date,
        appointmentData.start_time,
        timezoneOffset,
        appointmentData.duration
      )

      const fdate = dayjs(newDate).format(DATE_FORMATS.ISO_DATE_STRING)
      setFormattedDate(fdate)
      setNewTime(newTime)
      setStartTime(startTime)
      setEndTime(endTime)
      // Map fetched data to form values

      const mappedData = {
        appointment_id: appointmentData?.id,
        referenceNo: appointmentData?.reference_no,
        assignee: appointmentData?.assignee?.is_active ? appointmentData?.assignee?.id : 'null' || 'null',
        clientId: appointmentData.client?.id,
        vehicleId: appointmentData.vehicle?.id,
        appointmentDate: fdate || '',
        appointmentTime: newTime || '',
        fullName: appointmentData.client?.full_name || '',
        businessName: appointmentData.client?.business_name || '',
        businessPhone: appointmentData.client?.business_phone || '',
        primaryEmail: appointmentData.client?.primary_email || '',
        secondaryEmail: appointmentData.client?.secondary_email || '',
        referral: appointmentData.client?.referral_source || '',
        address: appointmentData.client?.address || '',
        phone: appointmentData.client?.phone || '',
        state: appointmentData.client?.state || '',
        city: appointmentData.client?.city || '',
        zipCode: appointmentData.client?.zip || '',
        useVin: appointmentData.vehicle?.vin ? true : false,
        vin: appointmentData.vehicle?.vin || '',
        vehicleYear: appointmentData.vehicle?.year || '',
        vehicleMake: appointmentData.vehicle?.make || '',
        vehicleModel: appointmentData.vehicle?.model || '',
        status: appointmentData.status || '',
        notes: appointmentData.notes || '',
        pickDeliver: appointmentData.delivery_pickup ? 'true' : 'false',
        aro: appointmentData.vehicle.repair_order_num || '',
        timezone_at: appointmentData.timezone_at,
        useExistingClient: false,
        duration: appointmentData.duration,
        businessId: '',
        recentEstimate: appointmentEstimateResponse?.data || null
      }
      setFormValues(mappedData)
      setOriginalValues(mappedData)
    } catch (error) {
      console.error('Error fetching appointment details:', error)
    }
  }

  const handleChange = (eventOrValue) => {
    if (eventOrValue && eventOrValue.target) {
      const { name, value, type, checked, files } = eventOrValue.target
      if (name === 'recentEstimate' && files) {
        const selectedFile = files[0]
        if (!ALLOWED_FILE_TYPES.includes(selectedFile.type)) {
          addMessage({
            text: 'Invalid file type. Please upload a PDF, DOCX, JPG, or PNG file.'
          })
          return
        }
        if (selectedFile.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
          addMessage({
            text: `File size exceeds the limit of ${MAX_FILE_SIZE_MB}MB.`
          })
          return
        }
        if (selectedFile.name?.length > 80) {
          addMessage({
            text: `The file name cannot exceed more than 80 characters.`
          })
          return
        }
        setFormValues((prev) => ({
          ...prev,
          recentEstimate: selectedFile
        }))
        if (validationMap[name]) {
          setFormValid((prev) => ({
            ...prev,
            [name]: validationMap[name](value.trim())
          }))
        }
      } else {
        if (validationMap[name]) {
          setFormValid((prev) => ({
            ...prev,
            [name]: validationMap[name](value.trim())
          }))
        }
        setFormValues((prev) => ({
          ...prev,
          [name]: type === 'checkbox' ? checked : value
        }))
      }
    } else if (eventOrValue) {
      let newAppointmentTime = originalValues.appointmentTime
      if (eventOrValue !== originalValues.appointmentDate) {
        newAppointmentTime = null
      }

      // Update the form values
      setFormValues((prev) => ({
        ...prev,
        appointmentTime: newAppointmentTime,
        appointmentDate: eventOrValue
      }))
    }
  }

  const redirectToNewWorkOrder = () => {
    history.push(REDIRECT_URLS.NEW_WORK_ORDER, { ...formValues })
  }
  const redirectToNewQuote = () => {
    history.push(REDIRECT_URLS.NEW_QUOTE, { ...formValues })

    // Scroll to top of the page
    scrollPageTop()
  }

  const handleFileDelete = () => {
    setFormValues((prev) => ({
      ...prev,
      recentEstimate: null
    }))

    // Optionally clear any errors related to the file
    setFormErrors((prev) => ({
      ...prev,
      recentEstimate: ''
    }))
  }

  useEffect(() => {
    if (currentUser) {
      setFormValues((prevState) => ({
        ...prevState,
        updatedBy: currentUser.id
      }))
    }
  }, [currentUser, setFormValues])

  const updateAssignees = async (value) => {
    try {
      setLoading(true)
      const response = await updateSchedularAssignee(ccId, selectedEventId, value)
      if (response.status === 200) {
        fetchAppointmentDetails()
        setRefresh((prev) => !prev)
      }
    } catch (error) {
      Logger.error({
        message: error,
        payload: { value, path: 'updateAssignees' }
      })
    } finally {
      setLoading(false)
    }
  }
  const updateStatus = async (value) => {
    try {
      setLoading(true)
      const response = await updateSchedularStatus(ccId, selectedEventId, value)
      if (response.status === 200) {
        fetchAppointmentDetails()
        setRefresh((prev) => !prev)
      }
    } catch (error) {
      Logger.error({
        message: error,
        payload: { value, path: 'updateStatus' }
      })
    } finally {
      setLoading(false)
    }
  }

  const extractMessage = (text) => {
    if (typeof text !== 'string' || !text.trim()) {
      return null
    }

    const messageMatch = text.match(/"message":"(.*?)"/)

    if (messageMatch && messageMatch[1]) {
      return messageMatch[1].replace('Bad arguments sent: ', '')
    }

    return null
  }

  const handleCustomError = (error) => {
    let errorMessage = ''
    if (error?.response?.status === 400) {
      errorMessage = extractMessage(error.response?.data?.message || '')
    }
    addMessage({
      text: errorMessage || error.response?.data?.message
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setEditMode(!editMode)
    if (editMode) {
      setLoading(true)
      if (formValues.recentEstimate instanceof File) {
        // need to check when we delete what we are getting
        const formData = new FormData()
        formData.append('file', formValues.recentEstimate)

        // Upload the file
        await createAppointmentEstimate(ccId, selectedEventId, formData)
      }
      const appointmentPayload = AppointmentPayload({
        formValues,
        organisationDetail
      })
      try {
        await updateAppointment(ccId, selectedEventId, appointmentPayload)
      } catch (error) {
        Logger.error({
          message: error,
          payload: { appointmentPayload, path: 'handleSubmit' }
        })
        handleCustomError(error)
      } finally {
        setRefresh((prev) => !prev)
        setLoading(false)
        cancel()
      }
    }
  }

  const handleClose = (event, value) => {
    setRefresh((prev) => !prev)
    if (event.type == 'confirm') {
      deleteAppointment()
    }
    if (value && value == 'backdropClick') return
    setOpen(false)
    setCancelAppointmentModal(false)
  }

  const deleteAppointment = async () => {
    try {
      setLoading(true)
      const response = await cancelAppointment(ccId, selectedEventId)

      setRefresh((prev) => !prev)
      cancel()
    } catch (error) {
      Logger.error({
        message: error,
        payload: { selectedEventId, path: 'deleteAppointment' }
      })
    } finally {
      setLoading(false)
    }
  }
  const closeModal = () => {
    setCancelAppointmentModal(true)
  }
  const defaultStyle = {
    borderRadius: '4px',
    padding: '0px',
    fontSize: '14px',
    height: '24px',
    minWidth: '120px'
  }
  const handleStatusChange = (event) => {
    const { name, value } = event.target
    if (name === 'status' && formValues && value) {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value
      }))
      updateStatus(value)
    } else {
      console.warn('Status change failed: Invalid form values or status.')
    }
  }
  const handleAssigneeChange = (event) => {
    const { name, value } = event.target

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }))

    updateAssignees(value)
  }

  const handlecancel = (event) => {
    setRefresh((prev) => !prev)
    cancel(event)
  }

  const isDisabled = () => {
    return formValues.appointmentDate ? dayjs(dayjs(formValues.appointmentDate).toDate()).diff(dayjs().toDate(), 'day') > -1 : false
  }

  const currentStatusStyle = schedularStatusStyles[formValues.status] || schedularStatusStyles.SCHEDULED

  const mobileDescription = `Confirm cancellation of this appointment. Event will be deleted from calendar, and a cancellation email will be sent once confirmed.`
  const desktopDescription = `Confirm cancellation of this appointment. Event will be deleted from <br /> calendar, and a cancellation email will be sent once confirmed.`
  return (
    <>
      <AdasBox sx={{ margin: '25px 24px' }}>
        <span>{formValues?.referenceNo ? `Appointment Details | ${formValues?.referenceNo}` : 'Appointment Details'}</span>
        <CloseIcon onClick={handlecancel} sx={{ float: 'right' }} fontSize='small'></CloseIcon>
      </AdasBox>
      <AdasCard
        sx={{
          margin: '17px 24px',
          padding: '16px 16px',
          backgroundColor: '#FDFBFF'
        }}
      >
        <AdasBox
          sx={{
            minHeight: availabilities?.overrides_date ? '58vh' : '75vh'
          }}
        >
          <AdasBox display='flex' justifyContent='space-between' mb={2}>
            <AdasBox>
              <AdasFormControl fullWidth sx={{ paddingBottom: '1.5rem' }}>
                <AdasSelect
                  sx={{ ...defaultStyle }}
                  labelId='AssigneeLabelID'
                  id='AssigneeID'
                  name='assignee'
                  value={formValues.assignee || 'Unassigned'}
                  onChange={handleAssigneeChange}
                  options={assigneeOptions}
                />
              </AdasFormControl>
            </AdasBox>
            <AdasBox>
              <AdasFormControl fullWidth>
                <AdasSelect
                  labelId='statusOfAppointmentLabelId'
                  sx={{
                    ...defaultStyle,
                    backgroundColor: currentStatusStyle.backgroundColor,
                    color: currentStatusStyle.color,
                    borderColor: currentStatusStyle.borderColor
                  }}
                  id='selectItems'
                  value={formValues.status || 'SCHEDULED'}
                  onChange={handleStatusChange}
                  options={statusOptions}
                  name='status'
                />
              </AdasFormControl>
            </AdasBox>
          </AdasBox>

          {editMode ? (
            <AppointmentForm
              formValues={formValues}
              handleChange={handleChange}
              assigneeOptions={assigneeOptions}
              type={true}
              setFormValues={setFormValues}
              appointmentId={selectedEventId}
              formErrors={formErrors}
              handleFileDelete={handleFileDelete}
              formValid={formValid}
              setFormValid={setFormValid}
              validationMap={validationMap}
              setAvailabilities={setAvailabilities}
              newTime={newTime}
              formattedDate={formattedDate}
            />
          ) : (
            <>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='date-time-content' id='date-time-header'>
                  <AdasTypography variant='subtitle2'>Date & Time</AdasTypography>
                </AccordionSummary>
                <AdasAccordionDetails>
                  <AdasBox display='flex' justifyContent='space-between'>
                    <AdasBox>
                      <AdasTypography variant='body2' color='#21212199'>
                        Date
                      </AdasTypography>
                      <AdasTypography color={theme.typography.shaded} variant='body2'>
                        {dayjs(formValues.appointmentDate).format(DATE_FORMATS.ISO_DATE_STRING)}
                      </AdasTypography>
                    </AdasBox>
                    <AdasBox>
                      <AdasTypography variant='body2' color='#21212199'>
                        Time
                      </AdasTypography>
                      <AdasTypography color={theme.typography.shaded} variant='body2'>
                        {startTime} - {endTime}
                      </AdasTypography>
                    </AdasBox>
                  </AdasBox>
                </AdasAccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='contact-info-content' id='contact-info-header'>
                  <AdasTypography variant='subtitle2'>Contact Info</AdasTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <AdasBox>
                    <AdasBox mb={2}>
                      <AdasTypography variant='body2' color='#21212199'>
                        Full Name
                      </AdasTypography>
                      <AdasTypography color={theme.typography.shaded} variant='body2' className='wordWrap'>
                        {formValues?.fullName}
                      </AdasTypography>
                    </AdasBox>
                    <AdasBox mb={2}>
                      <AdasTypography variant='body2' color='#21212199'>
                        Business Name
                      </AdasTypography>
                      <AdasTypography color={theme.typography.shaded} variant='body2' className='wordWrap'>
                        {formValues?.businessName}
                      </AdasTypography>
                    </AdasBox>
                    <AdasBox mb={2}>
                      <AdasTypography variant='body2' color='#21212199'>
                        Business Phone{' '}
                      </AdasTypography>
                      <AdasTypography color={theme.typography.shaded} variant='body2'>
                        {formatPhoneNumber(formValues?.businessPhone)}
                      </AdasTypography>
                    </AdasBox>
                    <AdasBox mb={2}>
                      <AdasTypography variant='body2' color='#21212199'>
                        Mobile Phone{' '}
                      </AdasTypography>
                      <AdasTypography color={theme.typography.shaded} variant='body2'>
                        {formatPhoneNumber(formValues?.phone)}
                      </AdasTypography>
                    </AdasBox>
                    <AdasBox mb={2}>
                      <AdasTypography variant='body2' color='#21212199'>
                        Primary Email{' '}
                      </AdasTypography>
                      <AdasTypography color={theme.typography.shaded} variant='body2' className='wordWrap'>
                        {formValues?.primaryEmail}
                      </AdasTypography>
                    </AdasBox>
                    <AdasBox mb={2}>
                      <AdasTypography variant='body2' color='#21212199'>
                        Secondary Email{' '}
                      </AdasTypography>
                      <AdasTypography color={theme.typography.shaded} variant='body2' className='wordWrap'>
                        {formValues?.secondaryEmail}
                      </AdasTypography>
                    </AdasBox>
                    <AdasBox mb={2}>
                      <AdasTypography variant='body2' color='#21212199'>
                        Referral{' '}
                      </AdasTypography>
                      <AdasTypography color={theme.typography.shaded} variant='body2' className='wordWrap'>
                        {formValues?.referral}
                      </AdasTypography>
                    </AdasBox>
                    {/* <AdasBox mb={2}>
                    <AdasTypography variant='body2' color='#21212199'>
                      Phone{' '}
                    </AdasTypography>
                    <AdasTypography color={theme.typography.shaded} variant='body2'>
                      {formValues?.phone}
                    </AdasTypography>
                  </AdasBox>
                  <AdasBox mb={2}>
                    <AdasTypography variant='body2' color='#21212199'>
                      Pick/Deliver Vehicle{' '}
                    </AdasTypography>
                    <AdasTypography color={theme.typography.shaded} variant='body2'>
                      {formValues?.pickDeliver}
                    </AdasTypography>
                  </AdasBox>
                  <AdasBox mb={2}>
                    <AdasTypography variant='body2' color='#21212199'>
                      Address{' '}
                    </AdasTypography>
                    <AdasTypography color={theme.typography.shaded} variant='body2'>
                      {formValues?.address}, {formValues?.city}, {formValues?.state}, {formValues?.zipCode}
                    </AdasTypography>
                  </AdasBox>
                  <AdasBox mb={2}>
                    <AdasTypography variant='body2' color='#21212199'>
                      Full Name
                    </AdasTypography>
                    <AdasTypography color={theme.typography.shaded} variant='body2'>
                      {formValues?.pickupContact?.fullName || '-'}
                    </AdasTypography>
                  </AdasBox>
                  <AdasBox mb={2}>
                    <AdasTypography variant='body2' color='#21212199'>
                      Phone{' '}
                    </AdasTypography>
                    <AdasTypography color={theme.typography.shaded} variant='body2'>
                      {formValues?.pickupContact?.phone || '-'}
                    </AdasTypography>
                  </AdasBox> */}
                  </AdasBox>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='vehicle-info-content' id='vehicle-info-header'>
                  <AdasTypography variant='subtitle2'>Vehicle Info</AdasTypography>
                </AccordionSummary>
                <AccordionDetails>
                  <AdasBox>
                    <AdasBox display='flex' mb={2}>
                      <AdasBox flex={1} mr={1}>
                        <AdasTypography variant='body2' color='#21212199'>
                          Vehicle Year
                        </AdasTypography>
                        <AdasTypography color={theme.typography.shaded} variant='body2'>
                          {formValues?.vehicleYear}
                        </AdasTypography>
                      </AdasBox>
                      <AdasBox flex={1} ml={4}>
                        <AdasTypography variant='body2' color='#21212199'>
                          Vehicle Make
                        </AdasTypography>
                        <AdasTypography color={theme.typography.shaded} variant='body2'>
                          {formValues?.vehicleMake}
                        </AdasTypography>
                      </AdasBox>
                    </AdasBox>

                    <AdasBox display='flex' mb={2}>
                      <AdasBox flex={1} mr={1}>
                        <AdasTypography variant='body2' color='#21212199'>
                          Vehicle Model
                        </AdasTypography>
                        <AdasTypography color={theme.typography.shaded} variant='body2'>
                          {formValues.vehicleModel}
                        </AdasTypography>
                      </AdasBox>
                      <AdasBox flex={1} ml={4}>
                        <AdasTypography variant='body2' color='#21212199'>
                          RO #
                        </AdasTypography>
                        <AdasTypography color={theme.typography.shaded} variant='body2'>
                          {formValues.aro}
                        </AdasTypography>
                      </AdasBox>
                    </AdasBox>

                    <AdasBox>
                      <AdasBox mb={2}>
                        <AdasTypography variant='body2' color='#21212199'>
                          Most Recent Estimate
                        </AdasTypography>
                        {formValues?.recentEstimate?.id ? (
                          <AppointmentAttachment
                            name='recentEstimate'
                            value={formValues.recentEstimate}
                            onChange={handleChange}
                            onDelete={handleFileDelete}
                            title='Please upload your most current estimate here.'
                            label='Upload Most Recent Estimate (Optional)'
                            isViewOnly={true}
                          />
                        ) : (
                          <>
                            <AdasBox
                              display='column'
                              alignItems='center'
                              align='center'
                              justifyContent='center'
                              mt={2}
                              py={'20px'}
                              maxWidth={'60%'}
                              textWrap='wrap'
                              border={'1px solid #0000001f'}
                              borderRadius={'4px'}
                            >
                              <PictureAsPdf fontSize='large' color='warning' />
                              <AdasTypography color={theme.typography.shaded} variant='body2'>
                                No estimate uploaded
                              </AdasTypography>
                            </AdasBox>
                          </>
                        )}
                        <AdasTypography color={theme.typography.shaded} variant='body2'></AdasTypography>
                      </AdasBox>
                      <AdasBox mb={2}>
                        <AdasTypography variant='body2' color='#21212199'>
                          Additional Notes
                        </AdasTypography>
                        <AdasTypography color={theme.typography.shaded} variant='body2' className='wordWrap'>
                          {formValues?.notes}
                        </AdasTypography>
                      </AdasBox>
                    </AdasBox>
                  </AdasBox>
                </AccordionDetails>
              </Accordion>
            </>
          )}

          <AdasBox sx={{ margin: '10px 0px' }}>
            <AdasStack
              direction={{ xs: 'column', sm: 'row' }} // Column on mobile, row on larger screens
              spacing={{ xs: '', sm: '2' }}
              divider={
                <AdasDivider
                  orientation='vertical'
                  flexItem
                  sx={{ backgroundColor: '#0071E3', display: { xs: 'none', sm: 'block' } }} // Hide divider on mobile
                />
              }
              justifyContent='flex-start' // Align left on both mobile and desktop
              alignItems={{ xs: 'flex-start', sm: 'center' }} // Align items to start on mobile, center on larger screens
            >
              <AdasButton
                startIcon={<AddIcon />}
                sx={{ textWrap: 'nowrap', width: { xs: '100%', sm: 'auto' }, justifyContent: { xs: 'flex-start', sm: 'center' } }} // Full width on mobile, auto on larger screens
                onClick={redirectToNewWorkOrder}
              >
                Create Work Order
              </AdasButton>

              <AdasButton
                startIcon={<AddIcon />}
                sx={{ textWrap: 'nowrap', width: { xs: '100%', sm: 'auto' }, justifyContent: { xs: 'flex-start', sm: 'center' } }} // Full width on mobile, auto on larger screens
                onClick={redirectToNewQuote}
                display='flex'
              >
                Create Quote
              </AdasButton>
            </AdasStack>
          </AdasBox>
        </AdasBox>
        <StickyFooter sx={{ backgroundColor: 'transparent' }}>
          {availabilities?.overrides_date && <DataOverrideCard availabilities={availabilities} />}
          <AdasBox mt={2} display='flex' justifyContent='end' gap={{ xs: 1, sm: 3 }}>
            <AdasButton
              startIcon={<EventBusyIcon />}
              sx={{ textWrap: 'nowrap' }}
              color='error'
              onClick={closeModal}
              disabled={!isDisabled()}
            >
              DELETE EVENT
            </AdasButton>
            {editMode ? (
              <AdasButton
                variant='contained'
                color='primary'
                onClick={handleSubmit}
                disabled={isSubmitDisabled}
                sx={{ padding: { xs: '4px', sm: '12px' }, textWrap: 'nowrap' }}
              >
                {'Update Event'}
              </AdasButton>
            ) : (
              <AdasButton
                variant='contained'
                startIcon={<ModeEditIcon />}
                color='primary'
                onClick={handleSubmit}
                disabled={!isDisabled()}
                sx={{ padding: { sm: '12px' }, textWrap: 'nowrap' }}
              >
                {'Edit Event'}
              </AdasButton>
            )}
          </AdasBox>
        </StickyFooter>
        <AdsCustomModal
          title='Send Updates'
          open={open}
          onClose={handleClose}
          description={'Appointment updates saved. Do you want to send the email confirmation?'}
          successButtonTitle={'Send'}
          cancelButtonTitle={'No, Don’t send'}
          cancelButtonConfig={{
            variant: 'outlined'
          }}
        />
        <AdsCustomModal
          title={`Cancel Appointment ${formValues?.referenceNo}`}
          open={cancelAppointmentModal}
          onClose={handleClose}
          description={
            <span
              dangerouslySetInnerHTML={{
                __html: isMobile ? mobileDescription : desktopDescription
              }}
            />
          }
          successButtonTitle={'Confirm'}
          cancelButtonTitle={'No'}
          cancelButtonConfig={{
            variant: 'outlined'
          }}
        >
          <AdasBox mt={3}>
            <AdasBox display='flex' gap={1} mb={1}>
              <AdasTypography variant='body2' color='#21212199'>
                Date & Time:{' '}
              </AdasTypography>
              <AdasTypography color={theme.typography.shaded} variant='body2'>
                {formatDate(formValues?.appointmentDate, DATE_FORMATS.ISO_DATE_STRING)} |
              </AdasTypography>
              <AdasTypography color={theme.typography.shaded} variant='body2'>
                {startTime}
              </AdasTypography>
            </AdasBox>
            <AdasBox display='flex' gap={1}>
              <AdasTypography variant='body2' color='#21212199' sx={{ textWrap: 'nowrap' }}>
                Full Name:
              </AdasTypography>
              <AdasTypography
                color={theme.typography.shaded}
                variant='body2'
                className='wordWrap'
                sx={{
                  maxWidth: { xs: '80%', sm: '100%' } // limits the width to prevent overflow
                }}
              >
                {formValues?.fullName}
              </AdasTypography>
            </AdasBox>
            <AdasBox display='flex' gap={1}>
              <AdasTypography variant='body2' color='#21212199'>
                Client:
              </AdasTypography>
              <AdasTypography
                color={theme.typography.shaded}
                variant='body2'
                className='wordWrap'
                sx={{
                  maxWidth: { xs: '80%', sm: '100%' } // limits the width to prevent overflow
                }}
              >
                {formValues?.businessName}
              </AdasTypography>
            </AdasBox>
          </AdasBox>
        </AdsCustomModal>
      </AdasCard>
    </>
  )
}
