import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

export const AdasCalendar = ({
  events,
  view,
  SchedularCalendarEvent,
  calendarRef,
  updateTitle,
  setIsSliderOpen,
  setSelectedEvent,
  setSelectedEventId,
  hiddenDays = [],
  slotHeight = '50px',
  ...props
}) => {
  const [calendarApi, setCalendarApi] = useState(null)

  useEffect(() => {
    if (calendarApi) {
      const slotElements = document.querySelectorAll('.fc-timegrid-slot')
      slotElements.forEach((slot) => {
        slot.style.height = slotHeight
      })
    }
  }, [calendarApi])

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi()
      calendarApi.changeView(view)
      updateTitle()
    }
  }, [view])

  const handleDatesSet = (dateInfo) => {
    setCalendarApi(dateInfo.view.calendar)
  }

  return (
    <>
      <FullCalendar
        ref={calendarRef}
        eventOverlap={true}
        slotEventOverlap={true}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView={view}
        headerToolbar={false}
        events={events}
        datesSet={handleDatesSet}
        hiddenDays={hiddenDays}
        {...props}
      />
    </>
  )
}

AdasCalendar.propTypes = {
  events: PropTypes.array.isRequired,
  SchedularCalendarEvent: PropTypes.elementType.isRequired,
  view: PropTypes.string.isRequired,
  calendarRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  updateTitle: PropTypes.func.isRequired,
  setIsSliderOpen: PropTypes.func.isRequired,
  setSelectedEvent: PropTypes.func.isRequired
}
