import { AdasAccordion, AdasAccordionSummary } from '@components/wrapper-components'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { DATE_FORMATS } from 'constants'
import { getTimeZoneByCode } from 'utils/utils'
import customParseFormat from 'dayjs/plugin/customParseFormat'

// Extend dayjs with UTC and Timezone support
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

export const appointmentInitialDetails = {
  fullName: '',
  businessName: '',
  businessPhone: '',
  primaryEmail: '',
  secondaryEmail: '',
  referral: '',
  pickDeliver: true,
  address: '',
  phone: '',
  city: '',
  state: '',
  zipCode: '',
  appointmentDate: '',
  appointmentTime: '',
  useExistingClient: true,
  businessId: '',
  useVin: true,
  vin: '',
  vehicleYear: '',
  vehicleMake: '',
  vehicleModel: '',
  aro: '',
  notes: '',
  recentEstimate: '',
  userId: '',
  userName: '',
  updatedBy: '',
  qwi_id: '',
  timezone_at: '',
  duration: '',
  pickupContact: {
    // New field added
    fullName: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zipCode: ''
  }
}

export const statusColorMap = {
  SCHEDULED: '#7b7979',
  ARRIVED: '#0567e1',
  IN_PROGRESS: '#f59e0b',
  ON_HOLD: '#f43f5e',
  IN_TRANSIT: '#a5faea',
  DELIVERED: '#047857'
}

export const statusOptions = [
  {
    id: 'SCHEDULED',
    name: 'Scheduled'
  },
  {
    id: 'ARRIVED',
    name: 'Arrived'
  },
  {
    id: 'IN_PROGRESS',
    name: 'In-Progress'
  },
  {
    id: 'ON_HOLD',
    name: 'On-Hold'
  },
  {
    id: 'IN_TRANSIT',
    name: 'In-Transit'
  },
  {
    id: 'DELIVERED',
    name: 'Completed'
  }
]

export const Accordion = styled((props) => <AdasAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid rgba(0, 0, 0, 0.12) `,
  borderRadius: '8px',
  marginBottom: '10px',
  '&:before': {
    display: 'none'
  }
}))

export const AccordionSummary = styled((props) => (
  <AdasAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',

  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))

export const formatDateOrReturn = (date, time, timeZoneCode) => {
  if (!date || !time) return ''

  // Combine date and time into one string
  const datetime = `${date} ${time}`

  // Convert using the specified timezone
  const timezone = getTimeZoneByCode(timeZoneCode)
  const convertedDate = dayjs(datetime) // Match format of 'datetime'
    .tz(timezone, true)
    .utc() // Apply timezone offset
    .format(DATE_FORMATS.ISO_DATE)
  return convertedDate
}

export const formatTimeOrReturn = (date, time, timeZoneCode) => {
  if (!date || !time) return ''

  // Combine date and time into one string
  const datetime = `${date} ${time}`

  const timezone = getTimeZoneByCode(timeZoneCode)
  const convertedTime = dayjs(datetime) // Match format of 'datetime'
    .tz(timezone, true)
    .utc() // Apply timezone offset
    .format('HH:mm')
  return convertedTime
}
export const formatExistingDateOrReturn = (date, startTime, timezoneOffset) => {
  if (!date || !startTime) return ''

  // Format time as HH:mm from the startTime array
  const time = `${startTime[0].toString().padStart(2, '0')}:${startTime[1].toString().padStart(2, '0')}`

  // Combine date and time into a single datetime string
  const datetime = `${date}T${time}`

  // Convert datetime from local time (based on timezoneOffset) to UTC
  const convertedDate = dayjs
    .tz(datetime, timezoneOffset) // Use timezoneOffset to interpret the local time
    .utc() // Convert to UTC
    .format('YYYY-MM-DD') // Format as needed

  return convertedDate
}

export const schedularStatusStyles = {
  SCHEDULED: {
    backgroundColor: '#E9ECEF', // Example color
    color: '#212121',
    borderColor: '#a7a9ab'
  },
  ARRIVED: {
    backgroundColor: '#E3EEFB',
    color: '#0567E1',
    borderColor: '#0567E1'
  },
  IN_PROGRESS: {
    backgroundColor: '#F5ECDD',
    color: '#B45309',
    borderColor: '#F59E0B'
  },
  ON_HOLD: {
    backgroundColor: '#FFE4E6',
    color: '#BE123C',
    borderColor: '#F43F5E'
  },
  IN_TRANSIT: {
    backgroundColor: '#a5faea',
    color: '#023627',
    borderColor: '#15ad91'
  },
  DELIVERED: {
    backgroundColor: '#DEEFE9',
    color: '#047857',
    borderColor: '#047857'
  }
}
