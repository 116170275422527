import { StatusEnum, BroadCastTypes } from 'enums/enums'

export const LABEL_DISPLAY = {
  REOPEN_INVOICE_LABEL: 'ReOpen Invoice',
  OPEN_LABEL: 'Open',
  CLOSED_LABEL: 'Close',
  AGED_INVOICE_LABEL: 'Aged Invoice',
  MONTHLY_VEHICLECOUNT_LABEL: 'Monthly RO Count',
  POTENTIAL_MONTHLY_RO_COUNT_LABEL: 'Potential Monthly RO Count',
  HELP_SECTION_LABEL: 'HELP SECTION',
  CALIBRATION_DIAGNOSTIC_LABEL: 'Calibration Diagnostic',
  CAR_ADAS_IT_LABEL: 'Car ADAS+ IT',
  SUPPORT_TYPE_LABEL: 'SUPPORT TYPE',
  REQUEST_TYPE_LABEL: 'REQUEST TYPE',
  WORK_ORDER_QUOTE_INVOICE_LABEL: 'Work Order/Quote/Invoice',
  INFORMATION_REQUEST_LABEL: 'Information Request',
  SCHEDULED_MAINTENANCE: 'Scheduled Maintenance',
  VERSION_NUMBER: 'v.2.0.09'
}

export const BUTTONS_DISPLAY_LABEL = {
  CANCEL: 'CANCEL',
  REOPEN_INVOICE_LABEL: 'REOPEN INVOICE',
  CONFIRM: 'CONFIRM',
  SCORECARD: 'SCORECARD',
  DELETE: 'DELETE',
  YES: 'YES',
  NO: 'NO',
  NEW_WORK_ORDER: 'NEW WORK ORDER',
  NEW_QUOTE: 'NEW QUOTE',
  CREATE: 'CREATE'
}

export const GENERIC_MESSAGES = {
  REOPEN_CODE: 'OPEN',
  REOPEN_INVOICE_TITLE: 'Reopen Closed Invoice',
  CONFIRM_REOPEN_INVOICE: 'To confirm that you want to reopen this Invoice, type ‘OPEN’ below.',
  ADMIN_CODE_TITLE: 'System Admin Code Required',
  ADMIN_CODE_REQUIRED: 'A System Admin code is required to reopen a closed Invoice.',
  REVERT_TO_WO_TITLE: 'Move Invoice back to a Work Order',
  REVERT_TO_WO: 'Click the Confirm button to move Open Invoice back to a Work Order',
  OPENING_DATE_CHECK: 'Opening date must be in the past',
  CONFIRM_DELETE_PHOTO: 'Are you sure you want to delete this photo?',
  DELETE_PHOTO: 'Delete Photo',
  PHOTOS_UPLOADED: ' Photos Uploaded',
  PHOTO_UPLOADED: ' Photo Uploaded',
  SEARCH_ALL: 'Search for Quotes, Work Orders and Invoices',
  VIN_MUST_MATCH: 'VIN must be an exact match',
  NO_RECORDS_FOUND: 'No Records Found',
  TRY_OTHER_VIN: 'Try another VIN or Open a New Work Order or Quote',
  SEARCH_RESULTS: 'SEARCH RESULTS',
  TIRE_PRESSURE_CHECK: 'Ensure all tires have correct tire pressure',
  INVOICE_CLOSING_TIMELINE: 'Invoices should be closed within 2 days of the Invoice Create date',
  CONTACT_DELETED: 'Contact Deleted',
  SAFETY_SYSTEMS_UPDATED: 'Safety Systems Updated',
  PRIMARY_DIAGNOSTIC_TOOL: 'Primary Diagnostic Tool Used During Calibration',
  MAX_POTOS: 'Max Photos',
  INVOICES_CAN_REOPEN_ASSISTANCE_MESSAGE: 'Please contact support@caradas.com for further assistance.',
  UNABLE_TO_REOPEN_INVOICE_TITLE: 'Unable to Reopen Invoice',
  INVOICES_CAN_REOPEN_WITH_IN_MONTH: 'Invoices can only be reopened in the month they were closed.',
  INVOICE_EXTRACT: 'Select the date range for the invoice extract',
  EXPORT_INVOICE_CSV: 'Export Invoice CSV',
  CREATE_SUPPORT_REQUEST: 'Create Support Request',
  SUPPORT_REQUESTING_ASSITANCE:
    'Before requesting assistance, please review the link below for documentation that may expedite the resolution of your issue',
  CONFIRM_CANCEL_REQUEST: 'Are you sure you want to cancel the request?',
  CANCEL_REQUEST_TITLE: 'Cancel Request',
  CONFIRM_UPDATE: 'Confirm Update',
  SUPPORT_UPDATE_INFO: 'An email will be sent if Resolution was updated. Are you sure you want to update the request?',
  EXPORT_INVOICE_CSV_DATE_RANGE_WARNING: 'Selected date cannot exceed two calendar months. Please update.',
  START_BROADCAST_TITLE: 'Admin Code to Start Broadcast',
  BROADCAST_INFO_MESSAGE: 'The message will be sent to all the users. Please enter the system admin code to confirm.',
  SYSTEM_ADMIN_CODE: 'System Admin Code',
  ADD_CALIBRATIONS: 'Add Calibrations',
  CLICK_CONFIRM_SERVICE_ONLY_WO_TO_STANDARD_WO_MESSAGE:
    'Click Confirm to convert Service Only work order to a Standard work order. Action cannot be undone.',
  END_BROADCAST_TITLE: 'End Broadcast',
  END_BROADCAST_MESSAGE: 'Are you sure you want to end the broadcast to all the users? This cannot be undone.',
  INCORRECT_ADMIN_CODE: 'Incorrect Admin Code',
  DELETE_USER: 'Delete User from Calibration Center',
  DELETE_USER_MESSAGE: 'User will be removed from calibration center. Click confirm to delete user. Action cannot be reverted.',
  OPT_IN_LINE_ITEMS_TITLE: 'Admin Code to Update QuickBooks',
  OPT_IN_LINE_ITEMS_YES_MESSAGE: 'Calibration Center has approved product line item invoicing in QBO',
  OPT_IN_LINE_ITEMS_NO_MESSAGE: 'Standard sales line item invoicing enabled by default',
  OEM_STATEMENT_HELPER_MESSAGE:
    '* OEM Position Statements are updated frequently. Please contact support if files should be added or updated.',
  DATE_OVERRIDES_MESSAGE: 'Use this option to change your availability for specific dates, overriding the weekly schedule.'
}

export const DATE_FORMATS = {
  ISO_DATE_STRING: 'MM/DD/YYYY',
  ISO_TIME_STRING: 'hh:mm a',
  ISO_DATE_TIME_STRING: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  ISO_DATE_TIME_SHORT_STRING: 'MM/DD/YYYY hh:mm a',
  ISO_DATE: 'YYYY-MM-DD',
  UTC_DATE_TIME: 'YYYY-MM-DDTHH:MM:00Z'
}

export const PARAM_NAMES = {
  REFERENCE_NUMBER: 'reference_number'
}

export const REDIRECT_URLS = {
  FORBIDDEN_PAGE: '/forbidden',
  INVOICE_SUMMARY: `/invoices/:${PARAM_NAMES.REFERENCE_NUMBER}/summary`,
  WORK_ORDER_SUMMARY: `/workorders/:${PARAM_NAMES.REFERENCE_NUMBER}/summary`,
  QUOTE_SUMMARY: `/quotes/:${PARAM_NAMES.REFERENCE_NUMBER}/summary`,
  QUOTE_DOCUMENT: `/quotes/:${PARAM_NAMES.REFERENCE_NUMBER}/document`,
  NEW_QUOTE: '/quote/new-quote',
  NEW_WORK_ORDER: '/workorder/new-work-order',
  WORK_ORDER_VEHICLE_INFO: `/workorders/:${PARAM_NAMES.REFERENCE_NUMBER}/vehicle-info`,
  WORK_ORDER_PHOTO: `/workorders/:${PARAM_NAMES.REFERENCE_NUMBER}/photos`,
  WORK_ORDER_POST_CALIBRATION: `/workorders/:${PARAM_NAMES.REFERENCE_NUMBER}/post-calibration`,
  WORK_ORDER_PRE_CALIBRATION: `/workorders/:${PARAM_NAMES.REFERENCE_NUMBER}/pre-calibration`,
  WORK_ORDER_DOCUMENT: `/workorders/:${PARAM_NAMES.REFERENCE_NUMBER}/document`,
  WORK_ORDER_CALIBRATION: `/workorders/:${PARAM_NAMES.REFERENCE_NUMBER}/calibration`,
  INVOICE_PHOTO: `/invoices/:${PARAM_NAMES.REFERENCE_NUMBER}/photo`,
  INVOICE_DOCUMENT: `/invoices/:${PARAM_NAMES.REFERENCE_NUMBER}/document`,
  QUOTE_VEHICLE_INFO: `/quotes/:${PARAM_NAMES.REFERENCE_NUMBER}/vehicle-info`,
  INVOICE_VEHICLE_INFO: `/invoices/:${PARAM_NAMES.REFERENCE_NUMBER}/vehicle-info`,
  INVOICE_POST_CALIBRATION: `/invoices/:${PARAM_NAMES.REFERENCE_NUMBER}/post-calibration`,
  INVOICE_PRE_CALIBRATION: `/invoices/:${PARAM_NAMES.REFERENCE_NUMBER}/pre-calibration`,
  INVOICE_CALIBRATION: `/invoices/:${PARAM_NAMES.REFERENCE_NUMBER}/calibration`,
  CLIENT_INVOICE_SUMMARY_PHOTO: `/invoice-summary/photo`,
  CLIENT_INVOICE_SUMMARY_DOCUMENT: `/invoice-summary/document`,
  CLIENT_INVOICE_SUMMARY: `/invoice-summary`
}

export const PHOTO_LABELS = {
  FRONT_CAMERA: 'FRONT CAMERA',
  ADD_PHOTO: 'ADD PHOTO',
  REAR_CAMERA: 'REAR CAMERA',
  SURROUND_VIEW_CAMERA: 'SURROUND VIEW CAMERA',
  BLIND_SPOT_MONITORING: 'BLIND SPOT MONITORING',
  FRONT_RADAR: 'FRONT RADAR',
  ADDITIONAL_PHOTOS: 'ADDITIONAL PHOTOS',
  CALIBRATION_SERVICE_PHOTOS: 'ADD CALIBRATION OR SERVICE PHOTO(S)',
  CALIBRATION_SERVICE_PHOTOS_1: 'ADDITIONAL CALIBRATION AND SERVICE PHOTOS'
}

export const FILE_UPLOAD = {
  TITLE: {
    UPLOAD_CALIBRATION_REPORT: 'UPLOAD CALIBRATION REPORT',
    UPLOAD_POST_TEST_DRIVE_SCAN_REPORT: 'UPLOAD POST TEST DRIVE SCAN REPORT',
    UPLOAD_COLLISION_REPAIR_ESTIMATE: 'UPLOAD COLLISION REPAIR ESTIMATE',
    UPLOAD_CALIBRATION_REQUIRED_REPORT: 'UPLOAD CALIBRATION REQUIRED REPORT',
    UPLOAD_PRE_CALIBRATION_SCAN_REPORT: 'UPLOAD PRE CALIBRATION SCAN REPORT',
    UPLOAD_WHEEL_ALIGNMENT_AUDIT: 'UPLOAD WHEEL ALIGNMENT AUDIT',
    UPLOAD_OEM_PROCEDURE: 'UPLOAD OEM PROCEDURE',
    UPLOAD_WHEEL_ALIGNMENT_REPORT: 'UPLOAD WHEEL ALIGNMENT REPORT',
    UPLOAD_PARTS_INVOICE: 'UPLOAD PARTS INVOICE'
  },
  LABEL: {
    CALIBRATION_REPORT: 'Calibration Report',
    POST_TEST_DRIVE_SCAN_REPORT: 'Post Test Drive Scan Report',
    COLLISION_REPAIR_ESTIMATE: 'Collision Repair Estimate',
    CALIBRATION_REQUIRED_REPORT: 'Calibration Required Report',
    PRE_CALIBRATION_SCAN_REPORT: 'Pre Calibration Scan Report',
    WHEEL_ALIGNMENT_AUDIT: 'Wheel Alignment Audit',
    OEM_PROCEDURE: 'OEM Procedure',
    WHEEL_ALIGNMENT_REPORT: 'Wheel Alignment Report',
    PARTS_INVOICE: 'Parts Invoice',
    ADDITIONAL_DOCUMENT: 'Additional Document'
  },
  TYPE_LABEL: {
    CALIBRATION_REPORT: 'calibrationReport',
    POST_TEST_DRIVE_SCAN_REPORT: 'postTestScanReport',
    COLLISION_REPAIR_ESTIMATE: 'collisionRepairEstimate',
    CALIBRATION_REQUIRED_REPORT: 'calRequiredReport',
    PRE_CALIBRATION_SCAN_REPORT: 'preCalReportScan',
    WHEEL_ALIGNMENT_AUDIT: 'wheelAlignmentAudit',
    OEM_PROCEDURE: 'oemProcedureScan',
    WHEEL_ALIGNMENT_REPORT: 'wheelAlignmentScan',
    PARTS_INVOICE: 'partsInvoice',
    ADDITIONAL_DOCUMENT: 'docWorkOrder',
    PRE_CALIBRATION_REPAIR_ESTIMATE_SCAN: 'preCalRepairEstimateScan'
  }
}

export const USER_TABLE_HEADERS = [
  { id: 'name', label: 'Name', minWidth: '150px' },
  { id: 'email', label: 'Email', minWidth: '100px' },
  { id: 'phone', label: 'Phone', minWidth: '100px' },
  { id: 'role', label: 'Role', minWidth: '100px' },
  { id: 'is_active', label: 'Status', width: '60px', align: 'center' }
]
export const CC_TABLE_HEADERS = [
  {
    id: 'name',
    label: 'CC Name',
    minWidth: '200px'
  },
  {
    id: 'is_active',
    label: 'Status',
    minWidth: '100px',
    align: 'center'
  },
  {
    id: 'id',
    label: 'CC #',
    minWidth: '100px'
  },
  {
    id: 'group_number',
    label: 'Group #',
    minWidth: '100px'
  },
  {
    id: 'group_name',
    label: 'Group Name',
    minWidth: '100px'
  },
  {
    id: 'support_lead',
    label: 'Support Lead',
    minWidth: '100px',
    align: 'left'
  },
  {
    id: 'owners',
    label: 'Owner',
    minWidth: '100px',
    align: 'left'
  },
  {
    id: 'opening_date',
    label: 'Open Date',
    minWidth: '100px',
    align: 'left'
  },
  {
    id: 'is_scheduler_enabled',
    label: 'Calendar',
    minWidth: '50px',
    align: 'left'
  },
  {
    id: 'is_pgw_enabled',
    label: 'PGW',
    minWidth: '50px',
    align: 'left'
  }
]

export const GLOBAL_DIRECTORY_HEADER = [
  {
    id: 'ccid',
    label: 'CC #',
    minWidth: '100px',
    align: 'left'
  },
  {
    id: 'center',
    label: 'CC Name',
    minWidth: '200px',
    align: 'left'
  },
  {
    id: 'name',
    label: 'User Name',
    minWidth: '200px',
    align: 'left'
  },
  {
    id: 'role',
    label: 'Role',
    minWidth: '100px',
    align: 'left'
  },
  {
    id: 'is_active',
    label: 'Status',
    minWidth: '100px',
    align: 'center'
  }
]

export const PRODUCT_LIST_HEADERS = [
  {
    id: 'name',
    label: 'Calibrations',
    minWidth: '200px',
    align: 'left'
  },
  {
    id: 'category',
    label: 'Category',
    minWidth: '200px',
    align: 'left'
  },
  {
    id: 'price',
    label: 'Price',
    minWidth: '100px'
  },
  {
    id: 'is_active',
    label: 'Status',
    minWidth: '100px',
    align: 'center'
  }
]

export const SERVICE_LIST_HEADERS = [
  {
    id: 'name',
    label: 'Services',
    minWidth: '200px',
    align: 'left'
  },
  {
    id: 'price',
    label: 'Price',
    minWidth: '100px'
  },
  {
    id: 'is_active',
    label: 'Status',
    minWidth: '100px',
    align: 'center'
  }
]

export const CLIENT_LIST_HEADERS = [
  { id: 'name', label: 'Client Name', align: 'left', minWidth: '200px' },
  { id: 'phone', label: 'Phone', align: 'left', minWidth: '100px' },
  { id: 'typeDescription', label: 'Type', align: 'left', minWidth: '80px' },
  {
    id: 'vehicle_count',
    label: LABEL_DISPLAY.MONTHLY_VEHICLECOUNT_LABEL,
    align: 'left',
    minWidth: '80px'
  },
  { id: 'is_active', label: 'Status', align: 'center', minWidth: '100px' }
]

export const SUPPORT_TICKETS_ADMIN_VIEW_HEADERS = (type) => {
  return [
    { id: 'created_at', label: 'DATE CREATED', align: 'left', minWidth: '30px' },
    ...(StatusEnum[type] === StatusEnum.CLOSED ? [{ id: 'closed_at', label: 'DATE CLOSED', align: 'left', minWidth: '30px' }] : []),
    ...(StatusEnum[type] === StatusEnum.OPEN ? [{ id: 'updated_at', label: 'DATE UPDATED', align: 'left', minWidth: '30px' }] : []),
    { id: 'supportTicketId', label: 'SUPPORT #', align: 'left', minWidth: '30px' },
    { id: 'support_type', label: 'SUPPORT TYPE', align: 'left', minWidth: '40px' },
    { id: 'problem_area', label: 'PROBLEM AREA', align: 'left', minWidth: '80px' },
    {
      id: 'calibration_name',
      label: 'CC NAME',
      align: 'left',
      minWidth: '30px'
    },
    { id: 'assignedTo', label: 'ASSIGNED TO', align: 'left', minWidth: '30px' },
    { id: 'supportTicketStatus', label: 'STATUS', align: 'left', minWidth: '20px' }
  ]
}

export const SUPPORT_TICKETS_CC_VIEW_HEADERS = [
  { id: 'created_at', label: 'DATE CREATED', align: 'left', minWidth: '30px' },
  { id: 'supportTicketId', label: 'SUPPORT #', align: 'left', minWidth: '30px' },
  { id: 'support_type', label: 'SUPPORT TYPE', align: 'left', minWidth: '40px' },
  { id: 'problem_area', label: 'PROBLEM AREA', align: 'left', minWidth: '80px' },
  {
    id: 'contact_name',
    label: 'CONTACT NAME',
    align: 'left',
    minWidth: '30px'
  },
  { id: 'supportTicketStatus', label: 'STATUS', align: 'left', minWidth: '20px' }
]

export const TABLE_HEADER_STYLE = {
  fontWeight: '600',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}

export const CLIENT_TYPE_ENUM = {
  CUSTOMERPAY: 'Customer Pay',
  FLEET: 'Fleet'
}

export const WORK_ORDER_TYPE = {
  STANDARD: 'Standard',
  SERVICE_ONLY: 'Service Only'
}

export const getQuoteSummaryUrl = (id) => {
  return REDIRECT_URLS.QUOTE_SUMMARY.replace(`:${PARAM_NAMES.REFERENCE_NUMBER}`, id)
}

export const getWorkOrderSummaryUrl = (id) => {
  return REDIRECT_URLS.WORK_ORDER_SUMMARY.replace(`:${PARAM_NAMES.REFERENCE_NUMBER}`, id)
}

export const getInvoiceSummaryUrl = (id) => {
  return REDIRECT_URLS.INVOICE_SUMMARY.replace(`:${PARAM_NAMES.REFERENCE_NUMBER}`, id)
}

export const getUrlByNameAndId = (urlPath = '', id) => {
  return urlPath.replace(`:${PARAM_NAMES.REFERENCE_NUMBER}`, id)
}

export const RadioGroup_Constants = [
  {
    value: BroadCastTypes.INFO,
    label: 'Information Update'
  },
  {
    value: BroadCastTypes.CRITICAL,
    label: 'Critical Update'
  }
]

export const DEFAULT_FAQS = [
  {
    q: 'What device was the Car ADAS Plus web-app built for?',
    a: ['Apple iPad', 'Any internet connected device can use Car ADAS Plus however the primary design is an iPad']
  },
  {
    q: 'How do I reset my password?',
    a: ['Click Forgot Password link from the Car ADAS Plus login page']
  },
  {
    q: 'How do I add a new user?',
    a: [
      'Role Level Access Requirement: Manager & Owner',
      'Go to Settings > Users and click the Plus sign to add a new user. The new user will receive a welcome email '
    ]
  },
  {
    q: 'Can a Technician or Manager have access to more than one location?',
    a: ['Yes.  The Manger or Owner can add an existing user to more than one location']
  },
  {
    q: 'What is a Standard and Service only Work Order type?',
    a: [
      'Standard Work Order includes Calibrations, Services and Custom Products',
      'Service Only Work Order does not contain calibration products or checklists'
    ]
  },
  {
    q: 'Can a Service Only Work Order be converted to a Standard Work Order?',
    a: ['Yes. A Service Only work order can be converted to a Standard work order by clicking the ‘Add Calibrations’ button']
  },
  {
    q: 'Are all documents required to be attached to a Work Order?',
    a: ['All Document uploads are optional']
  },
  {
    q: 'I am in an active Work Order and I left the record to update the Product price, why isn’t the Product price updated in my Work Order?',
    a: [
      'To maintain pricing controls for all other active Quote/Work Order records, the updated product price will only be available to new Quotes/Work Orders',
      'Add a new Custom Charge item to account for the pricing update in your active Work Order'
    ]
  },
  {
    q: 'Are the Custom Charge & Discount items permanently added to the Product Catalogue?',
    a: ['No.  Custom Charge & Discount items are a one-time use item']
  },
  {
    q: 'Can Product Names or Product Category be updated?',
    a: [
      'To establish an enterprise product catalogue and build unified financial reports and scorecards, the product name and product category are not editable fields',
      'Calibration Centers can create new products and services but the name and category cannot be edited once the item is created and given an item ID',
      'For products with fluctuating pricing, the Custom Charge Items is the recommended option'
    ]
  },
  {
    q: 'How do I connect to QuickBooks Online?',
    a: ['Manager and Owner roles are provisioned to access Settings > QuickBooks Connect to login and establish a connection to QBO']
  },
  {
    q: 'The Work Order has moved to an invoice, can the record still be edited?',
    a: [
      'Open Invoices can be edited by a Manager and Owner roles',
      'The Tech role does not have access to edit a completed work order',
      'Once an Invoice has been closed or sent to QuickBooks, the record is locked and read-only'
    ]
  },
  {
    q: 'Are keeping Client and Contacts up to date important?',
    a: [
      'Yes.  We encourage monthly reviews of each client to ensure data quality.  The client name, address and email are displayed on many files',
      'Keeping the client and contact emails up to date is very important as these email addresses are displayed during the emailing process'
    ]
  },
  {
    q: "In a quote, what should I enter if I don't have the RO number from the body shop?",
    a: ['You can enter "TBD" in the quote', 'The RO number can be updated at any time in the Vehicle Info page of a quote/work order']
  },
  {
    q: 'How do I setup my new QBO account?',
    a: [
      'To setup new QBO account, please follow the instructions mentioned <a href="https://shawandnielsencom-my.sharepoint.com/:w:/g/personal/jon_shawandnielsen_com/Edm8NTtG0rBDpsToaKIt_hIBqYwJi6h81D5U8xoDWDM1qw?e=k9QFeT&wdLOR=c9F77B5B8-A564-C04C-87E5-3DBF69126781">here</a>'
    ]
  },
  {
    q: 'How do I resolve QBO Duplicate Name Errors?',
    a: [
      'There is likely a duplicate error inside of your QBO account.  Review Customers, Employees and Vendors',
      'If you cannot resolve duplicate errors, you may need to call Intuit QuickBooks for them to identify the duplicate name on your QBO account (800-446-8848)',
      'To troubleshoot, follow the instructions mentioned <a href="https://quickbooks.intuit.com/learn-support/en-us/help-article/list-management/error-name-supplied-already-exists-another-vendor/L1WHjwNAJ_US_en_US">here</a>'
    ]
  },
  {
    q: 'How do I delete the temp files/caching/cookies in Chrome web browser?',
    a: [
      'Open Chrome',
      'Click the three-dot menu icon in the top right corner',
      'Hover over Tools and select Clear Browsing Data',
      'Choose a time range, or select All time to delete everything',
      'Check the boxes next to Cookies and other site data and Cached images and files',
      'Click Clear Data',
      'Close the browser and restart Chrome'
    ]
  },
  {
    q: 'How do I delete the temp files/caching/cookies in Safari web browser?',
    a: [
      'Click the Safari menu in the top left corner next to the Apple symbol',
      'Click Settings',
      'Click Privacy',
      'Click Manage Website Data',
      'Click Remove all to remove any cached data and cookies from your computer'
    ]
  }
]

export const ROLES_HIERARCHY = ['tech', 'manager', 'owner', 'admin']

export const LIMITS = {
  QBO_CLASS_NAME_SIZE: 15
}

export const CALENDAR_PREFERENCES_MESSAGES = {
  TECHNICIANS:
    'Number of available technicians. Appointments will be scheduled based on the technician value selected in drop-down list, allowing for multiple appointments to be scheduled at the same time.',
  DURATION: 'Duration of the appointment',
  GAP: 'Gap between each appointment',
  MINIMUMTIME: 'Minimum Advance Scheduling Time. Specifies the minimum amount of time required before an appointment can be scheduled',
  MAXIMUMDAYS: 'Maximum days in advance an appointment can be booked',
  MINHOURS: 'Minimum time allowed before an appointment can be rescheduled or canceled by external client online'
}

export const ERROR_MESSAGES = {
  TECHNICIANS: 'Invalid number of Technicians',
  DURATION: 'Appointment Duration is invalid',
  GAP: 'Gap Between Appointment is invalid',
  MINIMUMTIME: 'Minimum Time Before a Schedule that Client can book is invalid',
  MAXIMUMDAYS: 'Maximum Days Scheduled in Advance is invalid',
  MINHOURS: 'Minimum time required before the appointment to be rescheduled or canceled by an external client online is invalid'
}

export const TEXT_CONSTANTS = {
  INVALID_NAME: 'Invalid name. Only letters, numbers and spaces allowed.',
  INVALID_PHONE: 'Phone number is not valid.',
  INVALID_EMAIL: 'Email is not valid.',
  INAVLID_ALPHANUMERIC: 'Value should be alpha-numeric.',
  INVALID_ALPHABETIC_TEXT: 'Only letters are allowed.',
  INVALID_ZIP: 'Invalid ZIP Code'
}

export const MAX_NOTES_LENGTH = 200

export const SCHEDULER_AUTO_REFRESH_MINUTES = 15

export const MAX_FILE_SIZE_MB = 10
