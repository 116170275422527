import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import { useAuthStore } from '@caradasstore/AuthStore'
import { AssignedUser } from '@components/assigned-user'
import { CarImageHolder } from '@components/car-image-holder'
import { CustomItem, ItemList } from '@components/custom-item'
import { Drawers } from '@components/drawers'
import { EmailForm } from '@components/email'
import { IdDisplay } from '@components/id-display'
import { Note } from '@components/note'
import { VinInput } from '@components/vin'
import {
  AdasAlert,
  AdasBox,
  AdasButton,
  AdasContainer,
  AdasDivider,
  AdasModal,
  AdasPaper,
  AdasStack,
  AdasStep,
  AdasStepper,
  AdasTextField,
  AdasTypography,
  AdasImage
} from '@components/wrapper-components'
import CheckIcon from '@mui/icons-material/Check'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import { useTheme } from '@mui/material'
import {
  closeQuote,
  getCalibrationList,
  getQuote,
  getQuoteEmails,
  getQuotePdf,
  getQuoteByReferenceNumber,
  getServiceList,
  transformToWO,
  updateNote,
  updateQuote,
  updateWorkOrderCalibrations,
  updateWorkOrderServices,
  getQWIByQuoteId
} from '../../../api/api'
import { PARAM_NAMES, getWorkOrderSummaryUrl, REDIRECT_URLS, getUrlByNameAndId } from '../../../constants'
import { useLoadingStore } from '../../../store'
import { useScrollTop } from '../../../utils/useScrollTop'
import { currencyFormatter } from '../../../utils/utils'
import { StatusEnum } from 'enums/enums'
import { Logger } from '../../../logger'

export const QuoteSummaryPage = () => {
  useScrollTop()
  const { [PARAM_NAMES.REFERENCE_NUMBER]: referenceNumber } = useParams()
  const [packages, setPackages] = useState([])
  const [allCalibrations, setAllCalibrations] = useState()
  const [services, setServices] = useState([])
  const [allServices, setAllServices] = useState()
  const [isCalibrationOpen, setIsCalibrationOpen] = useState(false)
  const [isServiceOpen, setIsServiceOpen] = useState(false)
  const setLoading = useLoadingStore((store) => store.setLoading)
  const authCtx = useAuthStore()
  const [quoteData, setQuoteData] = useState({})
  const [quoteInputValues, setQuoteInputValues] = useState({
    notes_external: '',
    notes_internal: ''
  })
  const [closeInput, setCloseInput] = useState('')
  const [formData, setFormData] = useState({})
  const [isVinEdit, setIsVinEdit] = useState(false)
  const [isEmail, setIsEmail] = useState(false)
  const [emailContacts, setEmailContacts] = useState({})
  const [quotePdf, setQuotePdf] = useState()
  const [open, setOpen] = useState(false)
  const [updatedVehicle, setUpdatedVehicle] = useState()
  const [isVinInvalid, setIsVinInvalid] = useState(false)
  const [customItemList, setCustomItemList] = useState([])

  const textDisplay = { textAlign: 'center' }
  const theme = useTheme()

  const history = useHistory()
  const redirectVehicleInfo = () => {
    history.push(getUrlByNameAndId(REDIRECT_URLS.QUOTE_VEHICLE_INFO, referenceNumber))
  }
  const redirectDocuments = () => {
    history.push(getUrlByNameAndId(REDIRECT_URLS.QUOTE_DOCUMENT, referenceNumber))
  }
  const redirectWO = (data) => {
    history.push(getWorkOrderSummaryUrl(data?.reference_number))
  }

  const redirectToForbiddenPage = () => {
    history.replace(REDIRECT_URLS.FORBIDDEN_PAGE)
  }

  const onCloseModal = () => {
    setUpdatedVehicle(null)
    setIsVinEdit(false)
    setIsVinInvalid(false)
  }

  const packagesTotal = packages.length > 0 ? packages.reduce((total, current) => total + current.price, 0) : 0

  const servicesTotal = services.length > 0 ? services.reduce((total, current) => total + current.price, 0) : 0

  const customItemTotal =
    customItemList.length > 0
      ? customItemList.reduce((total, current) => {
          if (current.item_type === 'discount') {
            return total - current.price
          } else {
            return total + current.price
          }
        }, 0)
      : 0

  const totalCost = packagesTotal + servicesTotal + customItemTotal

  const handleConfirmClose = async () => {
    const closeQuoteResponse = closeQuote({
      quoteId: quoteData.id
    })
    closeQuoteResponse
      .then(() => {
        history.push({
          pathname: '/quotes',
          search: `?status=${quoteData.status || 'OPEN'}`
        })
      })
      .catch((error) => console.log(error))
  }
  const handleOpen = () => {
    setOpen(true)
  }

  function onClosePackages(data) {
    setIsCalibrationOpen(false)
    const updateSelectedCalibrationResponse = updateWorkOrderCalibrations({
      type: 'quotes',
      id: quoteData.id,
      calibrations: data
    })
    updateSelectedCalibrationResponse
      .then((response) => {
        if (response.status === 200) {
          setPackages(data)
        }
      })
      .catch((error) => console.log(error))
  }

  const onCloseService = (data) => {
    setIsServiceOpen(false)
    const updateWorkOrderServiceResponse = updateWorkOrderServices({
      type: 'quotes',
      id: quoteData.id,
      services: data
    })
    updateWorkOrderServiceResponse
      .then((response) => {
        if (response.status === 200) {
          setServices(data)
        }
      })
      .catch((error) => console.log(error))
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)

        const [calibrationListResponse, serviceListResponse] = await Promise.all([
          getCalibrationList({
            ccid: quoteData.calibrationCenter?.id,
            effectiveDate: quoteData.created_at
          }),
          getServiceList({
            ccid: quoteData.calibrationCenter?.id,
            effectiveDate: quoteData.created_at
          })
        ])

        const calibrations = {}
        const services = {}

        if (calibrationListResponse?.status === 200) {
          calibrationListResponse.data.forEach((item) => {
            calibrations[item.itemNum] = item
          })
        }

        if (serviceListResponse?.status === 200) {
          serviceListResponse.data.forEach((item) => {
            services[item.itemNum] = item
          })
        }

        const quoteResponse = await getQuote({
          quoteId: quoteData.id
        })

        if (quoteResponse.status === 200) {
          const data = quoteResponse.data
          data.quote.safetySystemsItems = data.safetySystemsItems
          setQuoteData(data.quote)
          setFormData(data.quote)
          if (data.workOrderCalibrations.length > 0) {
            const flatenedPackages = data.workOrderCalibrations.map((item) => ({
              ...item,
              calibration_id: item.calibration.id,
              name: item.calibration.name,
              price: item.calibration.price,
              description: item.calibration.description,
              calibration_center_id: item.calibration.calibration_center_id,
              itemNum: item.calibration.itemNum,
              category: item.calibration.category,
              is_defaulted: item.calibration.is_defaulted,
              is_active: item.calibration.is_active,
              calibration_created_at: item.calibration.created_at,
              calibration_updated_at: item.calibration.updated_at
            }))

            setPackages(flatenedPackages)
            flatenedPackages.forEach((item) => {
              calibrations[item.itemNum].checked = true
            })
          }

          if (data.workOrderServices.length > 0) {
            const flatenedServices = data.workOrderServices.map((item) => ({
              ...item,
              service_id: item.service.id,
              name: item.service.name,
              price: item.service.price,
              description: item.service.description,
              calibration_center_id: item.service.calibration_center_id,
              itemNum: item.service.itemNum,
              category: item.service.category,
              is_defaulted: item.service.is_defaulted,
              is_active: item.service.is_active,
              calibration_created_at: item.service.created_at,
              calibration_updated_at: item.service.updated_at
            }))

            setServices(flatenedServices)
            flatenedServices.forEach((item) => {
              services[item.itemNum].checked = true
            })
          }

          if (data.customItems.length > 0) {
            setCustomItemList(data.customItems)
          }

          if (data.quote.notes_external) {
            setQuoteInputValues((prev) => ({
              ...prev,
              notes_external: data.quote.notes_external
            }))
          }

          if (data.quote.notes_internal) {
            setQuoteInputValues((prev) => ({
              ...prev,
              notes_internal: data.quote.notes_internal
            }))
          }
        }

        setAllCalibrations(calibrations)
        setAllServices(services)
      } catch (error) {
        Logger.error({
          message: error,
          payload: { ccid: quoteData.calibrationCenter?.id, effectiveDate: quoteData.created_at, quoteId: quoteData.id }
        })
      } finally {
        setLoading(false)
      }
    }
    if (quoteData?.id) fetchData()
  }, [quoteData?.quoteCreatedAt, quoteData?.calibrationCenter?.id, quoteData?.created_at])

  useEffect(() => {
    const fetchQuoteDataByReferenceNumber = async () => {
      if (!referenceNumber) return

      try {
        setLoading(true)
        const response = await getQuoteByReferenceNumber({ referenceNumber })
        if (response?.status === 200) {
          const data = response.data || {}
          data.quote.safetySystemsItems = data.safetySystemsItems || []
          const QWIResponse = await getQWIByQuoteId(data.quote.id)
          if (QWIResponse?.data.invoiceId || QWIResponse?.data.workorderId) {
            history.replace(REDIRECT_URLS.FORBIDDEN_PAGE)
          }
          setQuoteData(data.quote)
        } else {
          //Given referenceNumber doesnot exist or no permission granted
          redirectToForbiddenPage()
          return
        }
      } catch (error) {
        Logger.error({
          message: error,
          payload: { referenceNumber }
        })
      } finally {
        setLoading(false)
      }
    }

    fetchQuoteDataByReferenceNumber()
  }, [referenceNumber])

  const saveNote = async (data) => {
    const response = await updateNote({ type: 'quotes', id: quoteData.id, data })
    if (response.status === 200 || response.status === 201) {
      setQuoteInputValues(data.note)
    }
  }
  const convertToWoUpdateVin = async () => {
    // eslint-disable-next-line no-unused-vars
    const { safetySystems, color, imageUrl, fuel, ...vehicle } = updatedVehicle
    const submitData = {
      ...formData,
      calibration_center_id: formData.calibrationCenter.id,
      client_id: formData.client.id,
      quote: false,
      vehicle: {
        calibration_center_id: authCtx.user, //change required
        exterior_color: color,
        image_url: imageUrl,
        fuel_capacity_gal: fuel,
        ...vehicle
      },
      safetySystems: updatedVehicle.safetySystems.map((item) => {
        return item.name
      })
    }
    const params = {
      data: submitData,
      quoteId: quoteData.id
    }

    const response = await updateQuote(params)
    if (response.status === 200 || response.status === 201) {
      const converWoResponse = await transformToWO(params)
      if (converWoResponse.status === 200 || converWoResponse.status === 201) {
        redirectWO({ reference_number: submitData.reference_number })
      }
    }
  }

  const convertToWO = async () => {
    const submitData = {
      ...formData,
      calibration_center_id: formData.calibrationCenter.id,
      client_id: formData.client.id,
      quote: false
    }

    const params = {
      data: submitData,
      quoteId: quoteData.id
    }
    if (!formData.vehicle.vin) {
      setIsVinEdit(true)
    } else {
      const response = await transformToWO(params)
      if (response.status === 200 || response.status === 201) {
        redirectWO({ reference_number: submitData.reference_number })
      }
    }
  }

  const updateVehicleInfo = (props) => {
    if (props.data?.make) {
      const updatedVehicle = {
        ...props.data,
        vin: props.vin
      }
      setUpdatedVehicle(updatedVehicle)
      setIsVinInvalid(false)
    } else {
      setUpdatedVehicle(null)
      setIsVinInvalid(true)
    }
  }

  const emailQuoteHandler = useCallback(async () => {
    const params = {
      quoteId: quoteData.id
    }

    setLoading(true)
    try {
      const emailContactResponse = await getQuoteEmails(params)
      if (emailContactResponse.status === 200) {
        setIsEmail(true)
        setEmailContacts(emailContactResponse.data)
      }
      const quotePdfResponse = await getQuotePdf({
        quoteId: quoteData?.id
      })
      if (quotePdfResponse.status === 200) {
        const fileValue = new File([quotePdfResponse.data], `quote_${quoteData?.reference_number}.pdf`)
        fileValue.id = quoteData?.id
        setQuotePdf(fileValue)
      }
    } catch (error) {
      Logger.error({ message: error, payload: { QuoteId: quoteData.id, path: 'QuoteSummaryPage/emailQuoteHandler' } })
    } finally {
      setLoading(false)
    }
  }, [quoteData])

  const vehicleData = quoteData?.vehicle || {}
  const isActive = StatusEnum[quoteData?.status] === StatusEnum.OPEN
  const isServiceOnly = quoteData?.type === 'SERVICE_ONLY'

  return (
    <AdasContainer maxWidth='lg'>
      {quoteData.id && (
        <AdasPaper elevation={0} sx={{ padding: '1.25rem', marginTop: '1.25rem', marginBottom: '60px' }}>
          <EmailForm
            quotePdf={quotePdf}
            isOpen={isEmail}
            contacts={emailContacts}
            emailData={quoteData}
            onClose={() => setIsEmail(false)}
          />
          <Drawers data={allCalibrations} isOpen={isCalibrationOpen} onClose={onClosePackages} title={'Calibrations'} />
          <Drawers data={allServices} isOpen={isServiceOpen} onClose={onCloseService} title={'Service'} />
          <AdasBox sx={{ marginBottom: '40px' }}>
            <AdasBox sx={{ overflow: 'hidden' }}>
              <span style={{ float: 'left' }}>
                <IdDisplay itemId={quoteData.reference_number} roNumber={quoteData.repair_order_num} itemType={'Quote'} />
              </span>
              <span style={{ float: 'right' }}>
                <AssignedUser type='quotes' workOrderId={quoteData.id} initialAssignee={quoteData.assigned_to} closedStatus={!isActive} />
                <span style={{ marginLeft: '20px' }}>Created: {new Date(quoteData.created_at).toLocaleDateString()}</span>
              </span>
            </AdasBox>
            <AdasBox id='carInfo'>
              {vehicleData?.year && (
                <AdasBox
                  id='image'
                  sx={{
                    marginTop: vehicleData.image_url ? '0px' : '40px'
                  }}
                >
                  {vehicleData.image_url ? <AdasImage src={vehicleData.image_url} alt='Vehicle' /> : <CarImageHolder showText={false} />}
                  <AdasTypography sx={{ ...textDisplay, fontSize: '24px' }}>
                    <b>{`${vehicleData.year} ${vehicleData.make} ${vehicleData.model} ${vehicleData.trim}`}</b>
                  </AdasTypography>
                </AdasBox>
              )}
              <AdasTypography
                sx={{
                  ...textDisplay,
                  fontSize: '20px',
                  fontWeight: '500'
                }}
              >
                <b>Total Cost: {currencyFormatter(totalCost)}</b>
              </AdasTypography>
            </AdasBox>
            <AdasBox id='clientInfo' sx={{ ...textDisplay }}>
              <AdasTypography sx={{ ...textDisplay }} color={theme.typography.secondaryColor}>
                {quoteData.client?.name}
              </AdasTypography>
            </AdasBox>
          </AdasBox>
          <AdasBox sx={{ margin: '10px 0px', textAlign: 'end' }}>
            <AdasStack
              direction='row'
              sx={{ width: '40%', display: 'inline-flex' }}
              spacing={2}
              divider={<AdasDivider orientation='vertical' flexItem sx={{ backgroundColor: '#0071E3' }} />}
            >
              <AdasButton disabled={!isActive} onClick={redirectVehicleInfo} startIcon={<InfoOutlinedIcon />}>
                Vehicle Info
              </AdasButton>
              <AdasButton disabled={!isActive} startIcon={<InsertDriveFileOutlinedIcon />} onClick={redirectDocuments}>
                Documents
              </AdasButton>
            </AdasStack>
          </AdasBox>
          <AdasBox>
            <AdasStepper orientation='vertical' connector={null} activeStep={-1}>
              <AdasStep key='packages'>
                <AdasPaper
                  variant='outlined'
                  sx={{
                    background: theme.palette.background.default
                  }}
                >
                  {!isServiceOnly && (
                    <>
                      <AdasBox sx={{ margin: '20px' }}>
                        <span>
                          <b>Calibrations</b>
                        </span>
                        <span style={{ float: 'right' }}>
                          <b>{currencyFormatter(packagesTotal)}</b>
                        </span>
                        <ItemList itemList={packages} name='Packages' />
                        <AdasBox sx={{ textAlign: 'end' }}>
                          <AdasButton
                            disabled={!isActive}
                            startIcon={<LibraryAddCheckOutlinedIcon />}
                            sx={{}}
                            onClick={() => setIsCalibrationOpen(true)}
                          >
                            Manage Calibrations
                          </AdasButton>
                        </AdasBox>
                      </AdasBox>
                      <AdasDivider orientation='horizontal' flexItem sx={{ margin: '20px' }} />
                    </>
                  )}

                  <AdasBox sx={{ margin: '20px' }}>
                    <span>
                      <b>Services</b>
                    </span>
                    <span style={{ float: 'right' }}>
                      <b>{currencyFormatter(servicesTotal)}</b>
                    </span>
                    <ItemList itemList={services} name='Services' />
                    <AdasBox sx={{ textAlign: 'end' }}>
                      <AdasButton
                        disabled={!isActive}
                        startIcon={<LibraryAddCheckOutlinedIcon />}
                        sx={{}}
                        onClick={() => setIsServiceOpen(true)}
                      >
                        Manage Services
                      </AdasButton>
                    </AdasBox>
                  </AdasBox>
                  <AdasDivider orientation='horizontal' flexItem sx={{ margin: '20px' }} />
                  <CustomItem
                    type='quotes'
                    customItemTotal={customItemTotal}
                    existingCustomItemList={customItemList}
                    workOrderData={quoteData}
                    onSaveCustomItem={(props) => setCustomItemList(props)}
                    disabled={!isActive}
                  />
                </AdasPaper>
              </AdasStep>
            </AdasStepper>
            <Note
              existingExternalNote={quoteInputValues.notes_external}
              existingInternalNote={quoteInputValues.notes_internal}
              submit={(props) => saveNote(props)}
              allowEdit={isActive}
            />
          </AdasBox>
          <AdasBox sx={{ paddingBottom: '20px' }}>
            <AdasButton disabled={!isActive} fullWidth variant='contained' startIcon={<EmailOutlinedIcon />} onClick={emailQuoteHandler}>
              Email Quote
            </AdasButton>
          </AdasBox>
          <AdasDivider orientation='horizontal' flexItem />
          <AdasBox sx={{ margin: '20px 0px' }}>
            <AdasBox
              direction='row'
              spacing={1}
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <AdasButton
                sx={{ fontWeight: '600' }}
                onClick={() =>
                  history.push({
                    pathname: '/quotes',
                    search: `?status=${quoteData.status || 'OPEN'}`
                  })
                }
                startIcon={<KeyboardDoubleArrowLeftIcon />}
              >
                Quotes
              </AdasButton>
              <AdasBox>
                <AdasButton
                  disabled={!isActive}
                  variant='outlined'
                  sx={{ fontWeight: '600' }}
                  onClick={handleOpen}
                  startIcon={<CheckIcon />}
                >
                  CLOSE QUOTE
                </AdasButton>
                <AdasModal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <AdasPaper
                    variant='outlined'
                    sx={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      paddingBottom: '20px'
                    }}
                  >
                    <h4>Close current quote?</h4>
                    <p>To confirm that you want to close this quote, type CLOSE below.</p>
                    <AdasTextField
                      onChange={(e) => setCloseInput(e.target.value.toUpperCase())}
                      name='closeInput'
                      sx={{ marginBottom: '20px' }}
                      id='closeInput'
                      label='close input'
                      variant='outlined'
                      value={closeInput}
                      fullWidth={true}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                    <AdasBox sx={{ display: 'flex' }}>
                      <AdasButton sx={{ marginLeft: 'auto' }} onClick={handleClose}>
                        NO
                      </AdasButton>
                      <AdasButton disabled={closeInput === 'CLOSE' ? false : true} onClick={handleConfirmClose}>
                        YES
                      </AdasButton>
                    </AdasBox>
                  </AdasPaper>
                </AdasModal>

                <AdasButton
                  variant='contained'
                  startIcon={<ReceiptLongOutlinedIcon />}
                  onClick={convertToWO}
                  disabled={!isActive}
                  isDebounceEnabled={true}
                  sx={{ marginLeft: '2rem' }}
                >
                  CREATE WORK ORDER
                </AdasButton>
                <AdasModal
                  open={isVinEdit}
                  onClose={onCloseModal}
                  aria-labelledby='modal-modal-title'
                  aria-describedby='modal-modal-description'
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <AdasPaper
                    variant='outlined'
                    sx={{
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      paddingBottom: '20px'
                    }}
                  >
                    <h4>Convert Quote to Work Order</h4>
                    <p>A 17 digit VIN is required to create a work order for the vehicle</p>
                    <VinInput updateVehicleInfo={updateVehicleInfo} />
                    {updatedVehicle && (
                      <AdasAlert
                        severity='success'
                        // color={theme.palette.background.highlight}
                        sx={{
                          borderRadius: '4px',
                          my: '6px',
                          py: '0px',
                          backgroundColor: theme.palette.background.highlight
                        }}
                      >
                        {`${updatedVehicle.year} ${updatedVehicle.make} ${updatedVehicle.model}`} - Confirmed
                      </AdasAlert>
                    )}
                    {isVinInvalid && (
                      <AdasAlert
                        severity='error'
                        // color={theme.palette.background.highlight}
                        sx={{
                          borderRadius: '4px',
                          my: '4px'
                        }}
                      >
                        VIN not found!
                      </AdasAlert>
                    )}
                    <AdasBox
                      sx={{
                        display: 'flex',
                        marginTop: '10px',
                        justifyContent: 'space-between'
                      }}
                    >
                      <AdasButton sx={{ marginLeft: 'auto' }} onClick={onCloseModal}>
                        CANCEL
                      </AdasButton>
                      <AdasButton
                        variant='contained'
                        disabled={updatedVehicle && updatedVehicle.vin ? false : true}
                        isDebounceEnabled={true}
                        onClick={convertToWoUpdateVin}
                      >
                        CREATE WORK ORDER
                      </AdasButton>
                    </AdasBox>
                  </AdasPaper>
                </AdasModal>
              </AdasBox>
            </AdasBox>
          </AdasBox>
        </AdasPaper>
      )}
    </AdasContainer>
  )
}
