import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import { POST_CALIBRATION_FIELD_MAP } from '../../../variable-maps'
import { useScrollTop } from '../../../utils/useScrollTop'
import { PhotoInput } from '@components/photo'
import { IdDisplay } from '@components/id-display'
import { StickyFooter } from '@components/sticky-footer'
import { OperationType, StatusEnum } from '../../../enums/enums'
import { FileUploadLongCard } from '@components/file-upload'
import { Logger } from '../../../logger'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { AssignedUser } from '@components/assigned-user'
import {
  AdasBox,
  AdasButton,
  AdasCheckbox,
  AdasContainer,
  AdasFormControl,
  AdasFormControlLabel,
  AdasFormGroup,
  AdasInputAdornment,
  AdasPaper,
  AdasTextField,
  AdasTypography
} from '@components/wrapper-components'
import { getPostCalibration, submitPostCalibration, getInvoiceByReferenceNumber, getWorkOrderByReferenceNumber } from '../../../api/api'
import { FILE_UPLOAD, PARAM_NAMES, REDIRECT_URLS } from '../../../constants'

export const PostCalibrationPage = (props) => {
  useScrollTop()
  const { [PARAM_NAMES.REFERENCE_NUMBER]: referenceNumber } = useParams()
  const { fromInvoice = false } = props
  const history = useHistory()

  const setLoading = useLoadingStore((store) => store.setLoading)
  const [workOrderData, setWorkOrderData] = useState({}) //Object holds WorkOrder/Invoice data
  const orderClosed = workOrderData.id && StatusEnum[workOrderData.status] === StatusEnum.CLOSED
  const [vehicleData, setVehicleData] = useState({})
  const loading = useLoadingStore((store) => store.loading)
  const [dataUrls, setDataUrls] = useState({
    dashPostCalibrationImageUrl: {},
    calibrationRequiredReport: '',
    postTestScanReport: ''
  })

  //const workOrderData = location.state
  //const vehicleData = workOrderData.vehicle
  const vehicleName = `${vehicleData.make} ${vehicleData.model} ${vehicleData.trim}`

  const [postCalInputValues, setPostCalInputValues] = useState({
    odo_current: '',
    odo_stop: '',
    is_safety_systems_active: false,
    is_acc_dist_setting_1: false,
    is_acc_dist_setting_2: false,
    is_acc_dist_setting_3: false,
    is_acc_dist_setting_4: false,
    is_acc_speed_adj_down: false,
    is_acc_speed_adj_up: false,
    is_bs_left_alert: false,
    is_bs_right_alert: false,
    is_ld_alert: false,
    is_ld_no_alert: false,
    is_lka_steer_corr: false,
    is_lka_no_steer_corr: false,
    is_cam_rear_pivot_lines: false,
    is_cam_rear_img_focused: false,
    is_pa_alert_front: false,
    is_pa_alert_rear: false,
    is_apc_parks_in_space: false,
    is_cta_fl_approach_kit: false,
    is_cta_rl_approach_kit: false,
    is_cta_fr_approach_kit: false,
    is_cta_rr_approach_kit: false,
    is_cam_360_stitched: false,
    is_cam_360_focused: false,
    is_col_alert_brake: false,
    is_attach_wheel_alignment_rep: false,
    is_post_test_scn_rpt: false,
    post_test_drive_performed_by: false
  })

  const isSubmitEnabled =
    !fromInvoice &&
    !orderClosed &&
    [
      postCalInputValues.is_safety_systems_active,
      postCalInputValues.odo_stop,
      postCalInputValues.is_post_test_scn_rpt,
      dataUrls.dashPostCalibrationImageUrl?.id
    ].every((value) => !!value)

  const fontCss = {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '0.15px'
  }

  const classes = {
    subHeading: {
      fontWeight: '700 !important',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '266%',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      color: 'rgba(26, 27, 31, 0.87)',
      padding: '10px 0px 0px 0px'
    }
  }

  const redirectToForbiddenPage = () => {
    history.replace(REDIRECT_URLS.FORBIDDEN_PAGE)
  }

  const handleFormInputChange = useCallback((e) => {
    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    if (e.target.name === 'odo_stop') {
      let digitsOnly = value.replace(/[^0-9]/g, '')
      digitsOnly = digitsOnly.slice(0, 7)
      value = digitsOnly
    }

    setPostCalInputValues((prevState) => {
      const updatedState = {
        ...prevState,
        [e.target.name]: value
      }
      return updatedState
    })
  }, [])

  const handlePostTestDriveChange = useCallback((value) => {
    setPostCalInputValues((prevState) => {
      const updatedState = {
        ...prevState,
        post_test_drive_performed_by: value
      }
      return updatedState
    })
  }, [])

  const savePostCalibration = useCallback(
    async (complete) => {
      const postCalDataSubmit = {
        ...postCalInputValues,
        is_postcalibration_complete: complete,
        post_test_drive_performed_by:
          postCalInputValues.post_test_drive_performed_by?.id > 0 ? postCalInputValues.post_test_drive_performed_by : null
      }

      const params = {
        data: postCalDataSubmit,
        workorderId: workOrderData.id
      }

      return submitPostCalibration(params)
    },
    [postCalInputValues, workOrderData.id]
  )

  const returnToSummaryHandler = useCallback(async () => {
    if (!workOrderData.is_postcalibration_complete) {
      await savePostCalibration(false)
    } else if (workOrderData.is_postcalibration_complete) {
      await savePostCalibration(true)
    }

    history.goBack()
  }, [fromInvoice, history, savePostCalibration, workOrderData])

  const submit = useCallback(async () => {
    const response = await savePostCalibration(true)
    if (response.status === 200 || response.status === 201) {
      history.goBack()
    }
  }, [fromInvoice, history, savePostCalibration, workOrderData])

  const removeFileHandler = useCallback((fileName) => {
    if (fileName) {
      setDataUrls((prevState) => {
        const dataUrls = {
          ...prevState,
          [fileName]: null
        }
        return dataUrls
      })
    }
  }, [])

  const requestPostCalibration = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await getPostCalibration(params)
      let existingPostCalibrationValues = {}
      if (response.status === 200) {
        const existingPostCalibration = response.data
        Object.keys(existingPostCalibration).forEach((key) => {
          if (existingPostCalibration[key]) {
            existingPostCalibrationValues[key] = existingPostCalibration[key]
          }
        })

        if (Object.keys(existingPostCalibrationValues).length !== 0) {
          setPostCalInputValues((preValues) => {
            const values = {
              ...preValues,
              ...existingPostCalibrationValues
            }
            return values
          })
        }
        Object.keys(POST_CALIBRATION_FIELD_MAP).forEach((key) => {
          if (existingPostCalibration[key]) {
            const attachmentDetail = existingPostCalibration[key]
            setDataUrls((prevState) => {
              const imageUrls = {
                ...prevState,
                [POST_CALIBRATION_FIELD_MAP[key]]: attachmentDetail
              }
              return imageUrls
            })
          }
        })
      }
    } catch (error) {
      Logger.error({ message: error, payload: { params } })
    } finally {
      setLoading(false)
    }
  }, [])

  const refresh = useCallback(
    (refreshedData) => {
      if (refreshedData) {
        const propertyName = refreshedData.imageLocation
        if (refreshedData.operation === OperationType.ROTATE) {
          setDataUrls((prevState) => ({
            ...prevState,
            [propertyName]: {
              ...prevState[propertyName],
              img_rotation: refreshedData.img_rotation
            }
          }))
        }
        if (refreshedData.operation === OperationType.DELETE) {
          setDataUrls((prevState) => ({
            ...prevState,
            [propertyName]: null
          }))
        }
        if (refreshedData.operation === OperationType.RETAKE) {
          const imageItemDetail = {
            img_rotation: refreshedData.img_rotation,
            id: refreshedData.attachmentId
          }

          setDataUrls((prevState) => ({
            ...prevState,
            [propertyName]: imageItemDetail
          }))
        }
      } else {
        const params = {
          workorderId: workOrderData.id
        }
        requestPostCalibration(params)
      }
    },
    [requestPostCalibration, workOrderData.id]
  )

  useEffect(() => {
    workOrderData?.id && refresh()
  }, [refresh])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (fromInvoice) {
          let response = await getInvoiceByReferenceNumber({ referenceNumber })
          if (response?.status === 200) {
            if (!response.data?.invoiceDto) {
              redirectToForbiddenPage()
              return
            }
            response.data.invoiceDto.id = response.data.workOrderDto.id // workOrderId
            setWorkOrderData(response.data.invoiceDto)
            setVehicleData(response.data.invoiceDto.vehicle)
          } else {
            //Given referenceNumber doesnot exist or no permission granted
            redirectToForbiddenPage()
            return
          }
        } else {
          let response = await getWorkOrderByReferenceNumber({ referenceNumber })
          if (response?.status === 200) {
            //Url Tampared i.e invoice/quote record tried to open in workorder path
            if (StatusEnum[response.data?.workOrder?.status] === StatusEnum.MOVED) {
              redirectToForbiddenPage()
              return
            }
            setWorkOrderData(response.data.workOrder)
            setVehicleData(response.data.workOrder.vehicle)
          } else {
            //Given referenceNumber doesnot exist or no permission granted
            redirectToForbiddenPage()
            return
          }
        }
      } catch (error) {
        Logger.error({
          message: error,
          payload: {
            referenceNumber
          }
        })
      }
    }
    if (referenceNumber) fetchData()
  }, [referenceNumber])

  const generatePhotoInputProps = (fieldLocation, labelText, title) => {
    const fieldData = dataUrls[fieldLocation] || {}

    return {
      labelText,
      imageLocation: fieldLocation,
      workorderId: workOrderData.id,
      onUpdate: refresh,
      disabled: orderClosed || fromInvoice,
      properties: fieldData,
      imageId: fieldData.id || '',
      img_rotation: fieldData.img_rotation || 0,
      title: title
    }
  }

  return (
    <AdasContainer maxWidth='lg'>
      {!loading && (
        <AdasPaper elevation={0} sx={{ padding: '1.25rem', marginTop: '1.25rem' }}>
          {workOrderData.reference_number && (
            <AdasBox sx={{ display: 'flow-root' }}>
              <AdasTypography component='span'>
                <AdasTypography component='span' sx={{ float: 'left' }}>
                  <IdDisplay
                    itemId={workOrderData.reference_number}
                    roNumber={workOrderData.repair_order_num}
                    itemType={fromInvoice ? 'Invoice' : 'WO'}
                  />
                </AdasTypography>
                <AdasTypography component='span' sx={{ float: 'right' }}>
                  <AdasTypography component='span'>{vehicleName}</AdasTypography>
                </AdasTypography>
              </AdasTypography>
            </AdasBox>
          )}
          <AdasBox>
            <h2>Post Calibration Checklist</h2>
          </AdasBox>
          <AdasBox>
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                background: '#FDFBFF',
                marginBottom: '20px'
              }}
            >
              <AdasTypography sx={classes.subHeading}>SAFETY SYSTEMS *</AdasTypography>
              <AdasFormGroup sx={{ paddingBottom: '10px' }}>
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_safety_systems_active}
                      onChange={handleFormInputChange}
                      name='is_safety_systems_active'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Verify all vehicle safety systems are activated'
                />
              </AdasFormGroup>
            </AdasPaper>
          </AdasBox>
          <AdasBox sx={{ marginTop: '20px' }}>
            <h3 style={{ marginBottom: '5px' }}>Dynamic System Verification</h3>
          </AdasBox>
          <AdasBox>
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                background: '#FDFBFF',
                marginBottom: '20px'
              }}
            >
              <AdasTypography sx={classes.subHeading}>ADAPTIVE CRUISE CONTROL (ACC)</AdasTypography>
              <AdasFormGroup sx={{ paddingBottom: '10px' }}>
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_acc_dist_setting_1}
                      onChange={handleFormInputChange}
                      name='is_acc_dist_setting_1'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Distance Setting 1'
                />
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_acc_dist_setting_2}
                      onChange={handleFormInputChange}
                      name='is_acc_dist_setting_2'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Distance Setting 2'
                />
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_acc_dist_setting_3}
                      onChange={handleFormInputChange}
                      name='is_acc_dist_setting_3'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Distance Setting 3'
                />
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_acc_dist_setting_4}
                      onChange={handleFormInputChange}
                      name='is_acc_dist_setting_4'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Distance Setting 4'
                />
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_acc_speed_adj_down}
                      onChange={handleFormInputChange}
                      name='is_acc_speed_adj_down'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Cruise Control Speed Adjust Down'
                />
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_acc_speed_adj_up}
                      onChange={handleFormInputChange}
                      name='is_acc_speed_adj_up'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Cruise Control Speed Adjust Up'
                />
              </AdasFormGroup>
            </AdasPaper>
          </AdasBox>
          <AdasBox>
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                background: '#FDFBFF',
                marginBottom: '20px'
              }}
            >
              <AdasTypography sx={classes.subHeading}>BLIND SPOT</AdasTypography>
              <AdasFormGroup sx={{ paddingBottom: '10px' }}>
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_bs_left_alert}
                      onChange={handleFormInputChange}
                      name='is_bs_left_alert'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Left Blind Spot Alert'
                />
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_bs_right_alert}
                      onChange={handleFormInputChange}
                      name='is_bs_right_alert'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Right Blind Spot Alert'
                />
              </AdasFormGroup>
            </AdasPaper>
          </AdasBox>
          <AdasBox>
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                background: '#FDFBFF',
                marginBottom: '20px'
              }}
            >
              <AdasTypography sx={classes.subHeading}>CROSS TRAFFIC ALERT</AdasTypography>
              <AdasFormGroup sx={{ paddingBottom: '10px' }}>
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_cta_fl_approach_kit}
                      onChange={handleFormInputChange}
                      name='is_cta_fl_approach_kit'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Front Left Approach'
                />
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_cta_fr_approach_kit}
                      onChange={handleFormInputChange}
                      name='is_cta_fr_approach_kit'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Front Right Approach'
                />
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_cta_rl_approach_kit}
                      onChange={handleFormInputChange}
                      name='is_cta_rl_approach_kit'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Rear Left Approach'
                />

                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_cta_rr_approach_kit}
                      onChange={handleFormInputChange}
                      name='is_cta_rr_approach_kit'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Rear Right Approach'
                />
              </AdasFormGroup>
            </AdasPaper>
          </AdasBox>
          <AdasBox>
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                background: '#FDFBFF',
                marginBottom: '20px'
              }}
            >
              <AdasTypography sx={classes.subHeading}>LANE DEPARTURE WARNING</AdasTypography>
              <AdasFormGroup sx={{ paddingBottom: '10px' }}>
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_ld_alert}
                      onChange={handleFormInputChange}
                      name='is_ld_alert'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Audible/Visual/Haptic Alert'
                />
              </AdasFormGroup>
            </AdasPaper>
          </AdasBox>
          <AdasBox>
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                background: '#FDFBFF',
                marginBottom: '20px'
              }}
            >
              <AdasTypography sx={classes.subHeading}>LANE KEEP ASSIST</AdasTypography>
              <AdasFormGroup sx={{ paddingBottom: '10px' }}>
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_lka_steer_corr}
                      onChange={handleFormInputChange}
                      name='is_lka_steer_corr'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Vehicle Steering Correction (No Turn Signal)'
                />
              </AdasFormGroup>
            </AdasPaper>
          </AdasBox>
          <AdasBox>
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                background: '#FDFBFF',
                marginBottom: '20px'
              }}
            >
              <AdasTypography sx={classes.subHeading}>PARK ASSIST</AdasTypography>
              <AdasFormGroup sx={{ paddingBottom: '10px' }}>
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_pa_alert_front}
                      onChange={handleFormInputChange}
                      name='is_pa_alert_front'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Front Audible/Visual/Haptic Alert'
                />
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_pa_alert_rear}
                      onChange={handleFormInputChange}
                      name='is_pa_alert_rear'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Rear Audible/Visual/Haptic Alert'
                />
              </AdasFormGroup>
            </AdasPaper>
          </AdasBox>
          <AdasBox>
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                background: '#FDFBFF',
                marginBottom: '20px'
              }}
            >
              <AdasTypography sx={classes.subHeading}>ACTIVE PARK ASSIST</AdasTypography>
              <AdasFormGroup sx={{ paddingBottom: '10px' }}>
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_apc_parks_in_space}
                      onChange={handleFormInputChange}
                      name='is_apc_parks_in_space'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Vehicle Parks within Designated Space'
                />
              </AdasFormGroup>
            </AdasPaper>
          </AdasBox>
          <AdasBox>
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                background: '#FDFBFF',
                marginBottom: '20px'
              }}
            >
              <AdasTypography sx={classes.subHeading}>360 AROUND VIEW CAMERA</AdasTypography>
              <AdasFormGroup sx={{ paddingBottom: '10px' }}>
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_cam_360_stitched}
                      onChange={handleFormInputChange}
                      name='is_cam_360_stitched'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Images are stitched together (no overlaps/gaps)'
                />
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_cam_360_focused}
                      onChange={handleFormInputChange}
                      name='is_cam_360_focused'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Images are focused'
                />
              </AdasFormGroup>
            </AdasPaper>
          </AdasBox>
          <AdasBox>
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                background: '#FDFBFF',
                marginBottom: '20px'
              }}
            >
              <AdasTypography sx={classes.subHeading}>REAR VIEW CAMERA</AdasTypography>
              <AdasFormGroup sx={{ paddingBottom: '10px' }}>
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_cam_rear_pivot_lines}
                      onChange={handleFormInputChange}
                      name='is_cam_rear_pivot_lines'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Directional Pivot Lines Turn w/Steering'
                />
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={postCalInputValues.is_cam_rear_img_focused}
                      onChange={handleFormInputChange}
                      name='is_cam_rear_img_focused'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Images are Focused'
                />
              </AdasFormGroup>
            </AdasPaper>
          </AdasBox>
          <AdasBox>
            <h3 style={{ marginBottom: '5px' }}>Post Test Drive</h3>
          </AdasBox>
          <AdasBox>
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                background: '#FDFBFF',
                marginBottom: '20px'
              }}
            >
              <AdasTypography sx={classes.subHeading}>MILEAGE *</AdasTypography>
              <PhotoInput {...generatePhotoInputProps('dashPostCalibrationImageUrl', 'DASH POST CALIBRATION')} />

              <AdasBox sx={{ display: 'flow-root', marginBottom: '20px' }}>
                <AdasTypography sx={{ float: 'left', ...fontCss }}>Odometer Pre Calibration</AdasTypography>
                <AdasTypography sx={{ float: 'right', ...fontCss }}>{postCalInputValues.odo_current} mi</AdasTypography>
              </AdasBox>
              <AdasBox sx={{ display: 'flow-root', marginBottom: '20px' }}>
                <AdasTypography sx={{ float: 'left', ...fontCss }}>Odometer Post Test Drive</AdasTypography>
                <AdasFormControl sx={{ float: 'right', width: '40%' }}>
                  <AdasTextField
                    id='fuel level'
                    label='Enter Current Mileage'
                    name='odo_stop'
                    value={postCalInputValues.odo_stop}
                    onChange={handleFormInputChange}
                    variant='outlined'
                    sx={{ backgroundColor: 'white' }}
                    InputProps={{
                      endAdornment: <AdasInputAdornment position='end'>MI</AdasInputAdornment>
                    }}
                    disabled={orderClosed || fromInvoice}
                  ></AdasTextField>
                </AdasFormControl>
              </AdasBox>
            </AdasPaper>
          </AdasBox>
          <AdasBox>
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                background: '#FDFBFF',
                marginBottom: '20px'
              }}
            >
              <AdasTypography sx={classes.subHeading}>POST TEST DRIVE SCAN REPORT</AdasTypography>
              <AdasBox sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <AdasFormGroup>
                  <AdasFormControlLabel
                    control={
                      <AdasCheckbox
                        checked={postCalInputValues.is_post_test_scn_rpt}
                        onChange={handleFormInputChange}
                        name='is_post_test_scn_rpt'
                        disabled={orderClosed || fromInvoice}
                      />
                    }
                    label='Complete Post Test Drive Scan Report *'
                  />
                </AdasFormGroup>
                <FileUploadLongCard
                  removeFile={() => removeFileHandler('postTestScanReport')}
                  existingData={dataUrls.postTestScanReport}
                  workOrderData={workOrderData}
                  title={FILE_UPLOAD.TITLE.UPLOAD_POST_TEST_DRIVE_SCAN_REPORT}
                  typeLabel={FILE_UPLOAD.TYPE_LABEL.POST_TEST_DRIVE_SCAN_REPORT}
                  disabled={orderClosed || fromInvoice}
                />
              </AdasBox>
              <AdasFormGroup
                sx={{
                  marginBottom: '20px',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={!!postCalInputValues.post_test_drive_performed_by}
                      onChange={handleFormInputChange}
                      name='post_test_drive_performed_by'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Post Test Drive Performed by:'
                />
                <AssignedUser
                  initialAssignee={postCalInputValues.post_test_drive_performed_by || { value: false }}
                  closedStatus={orderClosed || fromInvoice}
                  updatePostTestDriveBy={handlePostTestDriveChange}
                />
              </AdasFormGroup>
            </AdasPaper>
          </AdasBox>
          <StickyFooter>
            <AdasBox>
              <AdasButton variant='outlined' aria-label='return to Summary' color='primary' onClick={returnToSummaryHandler}>
                {orderClosed || fromInvoice ? '' : 'SAVE & '}RETURN TO SUMMARY
              </AdasButton>
              <AdasButton
                disabled={!isSubmitEnabled}
                sx={{ float: 'right' }}
                variant='contained'
                aria-label='Set up complete'
                color='primary'
                onClick={submit}
              >
                CHECKLIST COMPLETE
              </AdasButton>
            </AdasBox>
          </StickyFooter>
        </AdasPaper>
      )}
    </AdasContainer>
  )
}

PostCalibrationPage.propTypes = {
  fromInvoice: PropTypes.bool
}
