import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Container } from '@mui/material'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { StickyFooter } from '@components/sticky-footer'
import { AdasSearchMenu, AdasTypography, AdasGrid, AdasBox, AdasDivider, AdasTextField, AdasButton } from '@components/wrapper-components'
import theme from 'theme/theme'
import { AdsCustomModal } from '@components/wrapper-components'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { CALENDAR_PREFERENCES_MESSAGES, ERROR_MESSAGES } from 'constants'
import { useOrganisationStore } from '@caradasstore/OrganisationStore'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { createCalendarPreferences } from 'api/api'
import { Logger } from 'logger'
import { useCenterStore } from '@caradasstore/CenterStore'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useHistory } from 'react-router-dom'
import { PreferencesFormRow } from './PreferencesFormRow'

dayjs.extend(duration)

const techniciansOptions = [1, 2, 3, 4, 5, 6]
const durationOptions = [30, 60, 90]
const timeGapOptions = [0, 15, 30, 45, 60]
const daysOptions = [14, 21]
const hoursOptions = [
  { value: 0, name: '0 minutes' },
  { value: 30, name: '30 minutes' },
  { value: 60, name: '60 minutes' },
  { value: 90, name: '90 minutes' }
]

const CalendarPreferences = ({ initialSettings, setBookingLinkOpen, onSuccess }) => {
  const organisationId = useOrganisationStore((state) => state.organisationId)
  const organisationDetails = useOrganisationStore((state) => state.organisationDetail)
  const ccId = useCenterStore((state) => state.ccId)
  const setLoading = useLoadingStore((state) => state.setLoading)
  const [errors, setErrors] = useState({})
  const [isSaveEnabled, setIsSaveEnabled] = useState(false)

  const [open, setOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false) // Controls modal visibility

  const history = useHistory()
  const [navigateTo, setNavigateTo] = useState(null) // Keeps track of the route to navigate to
  const [unblock, setUnblock] = useState(null) // To store the unblock function

  const convertDaysToMinutes = (days) => dayjs.duration({ days }).asMinutes()
  const convertMinutesToDays = (minutes) => dayjs.duration(minutes, 'minutes').asDays()
  const convertMinutesToHours = (minutes) => (minutes === 30 ? 30 : dayjs.duration(minutes, 'minutes').asHours())
  const convertHoursToMinutes = (hours) => (hours === 30 ? 30 : dayjs.duration(hours, 'hours').asMinutes())

  // Maps organisation details to form settings
  const mapOrganisationDetails = (details) => ({
    technicians: details.available_tech,
    duration: details.appointment_duration,

    minTimeBeforeBooking: details.min_time,
    maxDaysAdvance: convertMinutesToDays(details.max_time),
    minHoursBeforeReschedule: details.min_update_time,
    notificationRecipients: details.notification_recipients
  })
  const [settings, setSettings] = useState(() =>
    organisationDetails ? { ...initialSettings, ...mapOrganisationDetails(organisationDetails) } : initialSettings
  )
  const validateSettings = () => {
    const newErrors = {}

    if (settings.technicians < 1 || settings.technicians > 6) {
      newErrors.technicians = ERROR_MESSAGES.TECHNICIANS
    }

    if (settings.duration < 30 || settings.duration > 240) {
      newErrors.duration = ERROR_MESSAGES.DURATION
    }

    if (settings.minTimeBeforeBooking < 0 || settings.minTimeBeforeBooking > 60) {
      newErrors.minTimeBeforeBooking = ERROR_MESSAGES.MINIMUMTIME
    }

    const maxTimeInMinutes = convertDaysToMinutes(settings.maxDaysAdvance)
    if (maxTimeInMinutes < 20160 || maxTimeInMinutes > 60480) {
      newErrors.maxDaysAdvance = ERROR_MESSAGES.MAXIMUMDAYS
    }

    const minUpdateTimeInMinutes = settings.minHoursBeforeReschedule
    if (minUpdateTimeInMinutes < 0 || minUpdateTimeInMinutes > 1440) {
      newErrors.minHoursBeforeReschedule = ERROR_MESSAGES.MINHOURS
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleChange = (field, value) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [field]: value
    }))
    setIsSaveEnabled(true)
  }
  const payload = {
    id: organisationId,
    available_tech: settings.technicians,
    appointment_duration: settings.duration,
    appointment_gap: 0, // setting 0 as we are hiding this
    min_time: settings.minTimeBeforeBooking,
    max_time: convertDaysToMinutes(settings.maxDaysAdvance),
    min_update_time: settings.minHoursBeforeReschedule,
    notification_recipients: settings.notificationRecipients
  }
  const handleSubmit = async () => {
    if (!validateSettings()) return

    try {
      setLoading(true)
      const response = await createCalendarPreferences(ccId, payload)
      if (response?.status) {
        setOpen(true)
        setIsSaveEnabled(false)
        onSuccess()
        if (unblock) {
          unblock()
        }
      }
    } catch (error) {
      Logger.error({
        message: error,
        payload: { payload, path: 'createCalendarPreferences' }
      })
    } finally {
      setLoading(false)
    }
  }
  const handleClose = (event, value) => {
    if (value && value == 'backdropClick') return
    setOpen(false)
    setBookingLinkOpen(false)
  }
  const viewBookingPage = (event, value) => {
    if (value && value == 'backdropClick') return
    setOpen(false)
    setBookingLinkOpen(true)
  }
  useEffect(() => {
    // Set up blocking
    const unblock = history.block((location) => {
      if (isSaveEnabled) {
        setNavigateTo(location.pathname)
        setOpenModal(true)
        return false // Block navigation
      }
      return true // Allow navigation
    })

    // Save the unblock function
    setUnblock(() => unblock)

    // Cleanup: unblock on component unmount
    return () => {
      if (unblock) {
        unblock()
      }
    }
  }, [isSaveEnabled, history])
  const handleCloseModal = (event, value) => {
    if (value && value === 'backdropClick') return
    if (event.type === 'confirm') {
      // Unblock navigation before navigating
      if (unblock) {
        unblock()
      }
      history.push({ pathname: '/scheduler' })
    } else {
      setOpenModal(false)
      setNavigateTo(null)
    }
  }
  const handleBackButtonClick = (path) => {
    if (isSaveEnabled) {
      setNavigateTo(path)
      setOpenModal(true) // Show modal if there are unsaved changes
    } else {
      console.log({ history })
      history.push(path) // If no changes, allow navigation
    }
  }

  return (
    <Container maxWidth='md'>
      <Box sx={{ minHeight: '75vh', mt: 4 }}>
        <AdasGrid container spacing={3}>
          <PreferencesFormRow title={CALENDAR_PREFERENCES_MESSAGES.TECHNICIANS}>
            <AdasSearchMenu
              fullWidth
              id='select-technician'
              select
              label={techniciansOptions?.length === 1 ? 'Technician *' : 'Technicians *'}
              variant='outlined'
              onChange={(e) => handleChange('technicians', e.target.value)}
              sx={{ marginBottom: '20px' }}
              name='technicians'
              value={settings.technicians}
              options={techniciansOptions?.map((option) => ({
                value: option,
                name: `${option} ${option === 1 ? 'Technician' : 'Technicians'}`
              }))}
              error={!!errors.technicians}
              helperText={errors.technicians}
            />
          </PreferencesFormRow>
          <PreferencesFormRow title={CALENDAR_PREFERENCES_MESSAGES.DURATION}>
            <AdasSearchMenu
              fullWidth
              id='select-duration'
              select
              label='Duration of Appointment'
              variant='outlined'
              onChange={(e) => handleChange('duration', e.target.value)}
              sx={{ marginBottom: '20px' }}
              name='duration'
              value={settings.duration}
              options={durationOptions?.map((option) => ({
                value: option,
                name: `${option} Minutes`
              }))}
              error={!!errors.duration}
              helperText={errors.duration}
            />
          </PreferencesFormRow>
          <PreferencesFormRow title={CALENDAR_PREFERENCES_MESSAGES.MINIMUMTIME}>
            <AdasSearchMenu
              fullWidth
              id='select-duration'
              select
              label='Minimum Time Before Booking'
              value={settings.minTimeBeforeBooking}
              onChange={(e) => handleChange('minTimeBeforeBooking', e.target.value)}
              variant='outlined'
              sx={{ marginBottom: '20px' }}
              name='minTimeBeforeBooking'
              options={timeGapOptions?.map((option) => ({
                value: option,
                name: `${option} Minutes`
              }))}
              error={!!errors.minTimeBeforeBooking}
              helperText={errors.minTimeBeforeBooking}
            />
          </PreferencesFormRow>
          <PreferencesFormRow title={CALENDAR_PREFERENCES_MESSAGES.MAXIMUMDAYS}>
            <AdasSearchMenu
              fullWidth
              id='select-duration'
              select
              label='Maximum Days in Advance'
              value={settings.maxDaysAdvance}
              onChange={(e) => handleChange('maxDaysAdvance', e.target.value)}
              variant='outlined'
              sx={{ marginBottom: '20px' }}
              name='maxDaysAdvance'
              options={daysOptions?.map((option) => ({
                value: option,
                name: `${option} Days`
              }))}
              error={!!errors.maxDaysAdvance}
              helperText={errors.maxDaysAdvance}
            />
          </PreferencesFormRow>
          <PreferencesFormRow title={CALENDAR_PREFERENCES_MESSAGES.MINHOURS}>
            <AdasSearchMenu
              fullWidth
              id='select-duration'
              select
              label='Minimum Time Before Reschedule'
              value={settings.minHoursBeforeReschedule}
              onChange={(e) => handleChange('minHoursBeforeReschedule', e.target.value)}
              variant='outlined'
              sx={{ marginBottom: '20px' }}
              name='minHoursBeforeReschedule'
              options={hoursOptions}
              error={!!errors.minHoursBeforeReschedule}
              helperText={errors.minHoursBeforeReschedule}
            />
          </PreferencesFormRow>
          <AdasGrid item xs={12}>
            <AdasDivider orientation='horizontal' sx={{ marginBottom: '10px', marginTop: '25px' }} />
          </AdasGrid>
          <AdasGrid item xs={12}>
            <AdasBox mb={3}>
              <AdasTypography fontWeight={theme.typography.fontWeightMedium} mb={1}>
                Notifications Recipients
              </AdasTypography>
              <AdasTypography mb={3} fontSize='small' color={theme.typography.shaded}>
                Choose the people who should receive the email notifications.
              </AdasTypography>
            </AdasBox>
            <AdasTextField
              label='Email Addresses'
              value={settings.notificationRecipients}
              onChange={(e) => handleChange('notificationRecipients', e.target.value)}
              fullWidth
              helperText='Place a comma between emails'
            />
          </AdasGrid>
        </AdasGrid>
        <AdsCustomModal
          title='Save Updates'
          open={openModal}
          onClose={handleCloseModal}
          description={'You have made changes to the Calendar Preferences. Please save them before moving to other settings.'}
          successButtonTitle={'Leave Without Saving'}
        />
        <StickyFooter sxProps={{ backgroundColor: '#ffff' }}>
          <AdasDivider orientation='horizontal' sx={{ marginY: '20px', marginX: '-40px' }} />
          <AdasBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <AdasButton startIcon={<ArrowBackIosNewIcon />} onClick={() => handleBackButtonClick('/scheduler')}>
              Back to Calendar
            </AdasButton>
            <AdasButton variant='contained' color='primary' disabled={!isSaveEnabled} onClick={() => handleSubmit(settings)}>
              Save
            </AdasButton>
          </AdasBox>
        </StickyFooter>
      </Box>
      <AdsCustomModal
        title='Your Appointment Schedule is Live'
        titleConfig={{
          color: theme.typography.success,
          startIcon: <CheckCircleOutlineIcon color={theme.typography.success} sx={{ fontSize: { xs: '1.5rem', sm: '2.5rem' } }} />
        }}
        open={open}
        onClose={handleClose}
      >
        <AdasBox
          px={{ xs: 1, sm: 3 }}
          pt={{ xs: 2, sm: 3 }}
          display='flex'
          flexDirection={{ xs: 'column', sm: 'row' }}
          justifyContent={{ xs: 'center', sm: 'flex-end' }}
          gap={2}
        >
          <AdasButton
            startIcon={<InsertLinkIcon />}
            onClick={viewBookingPage}
            textTransform='uppercase'
            sx={{
              fontSize: { xs: '0.75rem', sm: '1rem' },
              padding: { xs: '8px 12px', sm: '10px 16px' },
              width: { xs: '100%', sm: 'auto' }
            }}
          >
            View booking page
          </AdasButton>
          <AdasButton
            variant='contained'
            textTransform='uppercase'
            color='primary'
            onClick={handleClose}
            sx={{
              fontSize: { xs: '0.75rem', sm: '1rem' },
              padding: { xs: '8px 12px', sm: '10px 16px' },
              width: { xs: '100%', sm: 'auto' }
            }}
          >
            Okay
          </AdasButton>
        </AdasBox>
      </AdsCustomModal>
    </Container>
  )
}

CalendarPreferences.propTypes = {
  initialSettings: PropTypes.shape({
    technicians: PropTypes.number,
    duration: PropTypes.number,
    gap: PropTypes.number,
    minTimeBeforeBooking: PropTypes.number,
    maxDaysAdvance: PropTypes.number,
    minHoursBeforeReschedule: PropTypes.number,
    notificationRecipients: PropTypes.string
  })
}

CalendarPreferences.defaultProps = {
  initialSettings: {
    technicians: 2,
    duration: 90,
    gap: 0,
    minTimeBeforeBooking: 0,
    maxDaysAdvance: 14,
    minHoursBeforeReschedule: 30,
    notificationRecipients: ''
  }
}

export default CalendarPreferences
