import PropTypes from 'prop-types'
import { Close } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import theme from 'theme/theme'
import { AdasBox, AdasTypography, AdasButton } from '@components/wrapper-components'

export const AdsCustomModal = ({
  title,
  open,
  description = '',
  onClose,
  children,
  titleConfig = {},
  successButtonTitle = '',
  successButtonConfig = {},
  cancelButtonTitle = '',
  cancelButtonConfig = {},
  hideCloseIcon = false
}) => {
  /**
   * Handles the close action for the modal
   * @param {object} event - The event object
   */
  const handleClose = (event) => {
    onClose({ type: 'close' })
  }

  /**
   * Handles the confirm action for the modal
   */
  const handleConfirm = () => {
    onClose({ type: 'confirm' })
  }

  return (
    <Dialog open={open} disableEscapeKeyDown fullWidth maxWidth='sm'>
      <AdasBox sx={{ minWidth: { xs: '350', sm: '400' } }}>
        <DialogTitle>
          <AdasTypography sx={{ fontWeight: '700', fontSize: { xs: '16px', sm: '18px' } }} color={theme.typography.shaded} {...titleConfig}>
            {titleConfig?.startIcon && (
              <>
                <AdasButton variant='body2' {...titleConfig?.startIcon?.props}>
                  {titleConfig.startIcon}
                </AdasButton>
              </>
            )}
            {title}
          </AdasTypography>
          {!hideCloseIcon && (
            <AdasButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: { xs: 4, sm: 8 },
                top: { xs: 8, sm: 8 },
                color: (theme) => theme.typography.shaded,
                padding: 0,
                minWidth: 'auto',
                fontSize: { xs: '1.25rem', sm: '1.5rem' }
              }}
            >
              <Close />
            </AdasButton>
          )}
        </DialogTitle>
        <DialogContent>
          <AdasBox mt={1}>
            {description && (
              <AdasBox>
                <AdasTypography variant='body1' color={theme.typography.shaded} component='span' className='wordWrap'>
                  {description}
                </AdasTypography>
              </AdasBox>
            )}
            {children && <>{children}</>}

            <AdasBox px={{ xs: '0', sm: '3' }} pt={3} display='flex' justifyContent='flex-end'>
              <AdasButton onClick={handleClose} textTransform='uppercase' sx={{ mr: 2 }} {...cancelButtonConfig}>
                {cancelButtonTitle}
              </AdasButton>
              {successButtonTitle && (
                <AdasButton variant='contained' textTransform='uppercase' color='primary' onClick={handleConfirm} {...successButtonConfig}>
                  {successButtonTitle}
                </AdasButton>
              )}
            </AdasBox>
          </AdasBox>
        </DialogContent>
      </AdasBox>
    </Dialog>
  )
}

AdsCustomModal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  description: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  successButtonTitle: PropTypes.string,
  successButtonConfig: PropTypes.object,
  cancelButtonTitle: PropTypes.string,
  cancelButtonConfig: PropTypes.object
}
